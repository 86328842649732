import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
// import { loadFull } from "tsparticles";
// import { useCallback, useMemo, useEffect, useState } from "react";
import { useMemo, useEffect, useState } from "react";

export const ParticlesComponent = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    // // console.log(container);
  };

  const options = useMemo(
    () => ({
      background: {
        color: "",
      },
      interactivity: {
        events: {
          onClick: {
            enable: true, // enables the click event
            mode: "push", // adds the particles on click
          },
          onHover: {
            enable: true, // enables the hover event
            mode: "repulse", // make the particles run away from the cursor
          },
        },
        modes: {
          push: {
            quantity: 0, // number of particles to add on click
          },
          repulse: {
            radius: 100, // the distance of the particles from the cursor
          },
        },
      },
      particles: {
        number: {
          value: 80, // default quantity of particles
          density: {
            enable: true,
            area: 800, // adjust this value to control the density
          },
        },
        links: {
          enable: true, // enabling this will make particles linked together
          color: "#000000",
        },
        move: {
          enable: true, // enabling this will make particles move in the canvas
          speed: { min: 1, max: 5 }, // using a range will make particles move in a random speed between min/max values, each particles have its own value, it won't change in time by default
        },
        opacity: {
          value: { min: 0.3, max: 0.7 }, // using a different opacity, to have some semitransparent effects
        },
        size: {
          value: { min: 1, max: 3 }, // let's randomize the particles size a bit
        },
        color: {
          value: "#000000", // color of the particles, you can also use an array of colors
        },
      },
    }),
    [],
  );

  if (init) {
    return (
      <div style={{ position: "relative", zIndex: -1 }}>
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        />
      </div>
    );
  }

  return <></>;
};

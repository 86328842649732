import React, { useEffect, useRef } from "react";
import { MdOutlineTimeline } from "react-icons/md";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { FaList } from "react-icons/fa6";

export const TimelineSidebar = ({
  segments,
  currentSegmentIndex,
  setCurrentSegmentIndex,
  handleSeek,
  isTimelineCollapsed,
  setIsTimelineCollapsed,
}) => {
  const segmentRefs = useRef([]);

  useEffect(() => {
    if (segmentRefs.current[currentSegmentIndex]) {
      segmentRefs.current[currentSegmentIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentSegmentIndex]);

  return (
    <div
      className={`relative transition-all duration-300 ease-in-out ${
        isTimelineCollapsed ? "w-14" : "w-[14rem]"
      } bg-white border-r border-gray-200 h-full flex flex-col`}
    >
      {/* Mid Toggle Button (right edge of sidebar) */}
      <button
        onClick={() => setIsTimelineCollapsed(!isTimelineCollapsed)}
        className="absolute right-3 top-1/2 translate-y-[-50%] translate-x-full bg-white border border-gray-300 shadow rounded-full p-1 z-50"
        aria-label={isTimelineCollapsed ? "Expand Timeline" : "Collapse Timeline"}
      >
        {isTimelineCollapsed ? (
          <FaChevronRight className={`text-lg ${
            isTimelineCollapsed ? "text-blue-500" : "text-gray-600"
          }`} />
        ) : (
          <FaChevronLeft className="text-lg text-gray-600" />
        )}
      </button>

      {/* Optional Top Icon Section (can remove if not needed) */}
      <div className="flex items-center justify-center h-14 border-b border-gray-200 relative">
        <button
          onClick={() => setIsTimelineCollapsed(!isTimelineCollapsed)}
          className="focus:outline-none flex flex-col items-center space-y-1"
          aria-label={isTimelineCollapsed ? "Expand Timeline" : "Collapse Timeline"}
        >
          <FaList
            className={`${
              isTimelineCollapsed ? "text-blue-500" : "text-gray-600"
            } text-lg`}
          />
          {isTimelineCollapsed && (
            <span className="text-xs text-blue-500 font-semibold">Timeline</span>
          )}
        </button>
      </div>

      {/* Segment List */}
      {!isTimelineCollapsed && (
        <div className="overflow-y-auto h-full">
          <ul>
            {segments.map((segment, index) => (
              <li
                key={index}
                ref={(el) => (segmentRefs.current[index] = el)}
                className={`p-2 cursor-pointer ${
                  currentSegmentIndex === index
                    ? "bg-blue-100"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => {
                  setCurrentSegmentIndex(index);
                  handleSeek(segment.start);
                }}
              >
                <div className="font-semibold">{segment.title}</div>
                <div className="text-sm text-gray-500">{segment.start}</div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};


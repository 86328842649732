import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStairs } from "@fortawesome/free-solid-svg-icons";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
// Tabs Component
export const Tabs = ({ tabs, selected, setSelected, className, id }) => {
  return (
    <div
      className={`py-2 w-full flex items-center gap-4 justify-center z-20 flex-nowrap ${className}`}
      id={id}
    >
      {tabs.map((tab) => (
        <Chip
          text={tab}
          selected={selected === tab}
          setSelected={setSelected}
          key={tab}
        />
      ))}
    </div>
  );
};

// Updated Chip Component
const Chip = ({ text, selected, setSelected }) => {
  const isSteps = text === "Steps"; // Special treatment for "Steps" tab

  return (
    <motion.button
      onClick={() => setSelected(text)}
      className={`relative px-3 py-1 rounded-full transition-all duration-300 border ${
        selected
          ? "bg-[#6196f3] border-[#6196f3]"
          : "border-gray-300 hover:bg-gray-100"
      } ${isSteps ? "w-12 h-12 flex items-center justify-center" : ""}`} // Circular style for "Steps" or icon button
      animate={{ scale: selected ? 1.1 : 1 }}
      transition={{ duration: 0.2 }}
    >
      {isSteps ? (
        // Apply text color classes directly to the icon
        <FontAwesomeIcon
          icon={faTasks}
          className={`text-lg ${selected ? "text-white" : "text-gray-600"}`}
        />
      ) : (
        // Apply text color classes directly to the text
        <span
          className={`relative z-10 font-medium text-sm ${
            selected ? "text-white" : "text-gray-600"
          }`}
        >
          {text}
        </span>
      )}
      {selected && (
        <span
          className={`absolute inset-0 z-0 rounded-full ${
            isSteps ? "" : "bg-[#6196f3]"
          }`}
        ></span>
      )}
    </motion.button>
  );
};

import styles from "./Layout.module.css";
import Header from "./header/header";
import React, { createContext, useState, useContext } from "react";

export const FocusContext = createContext(null);

const Layout = ({ children, headerMsg }) => {
  const [isFocus, setIsFocus] = useState(false);
  const [focusMsg, setFocusMsg] = useState("");
  
  return (
    <FocusContext.Provider value={{ isFocus, setIsFocus, focusMsg, setFocusMsg }}>
      <div className={styles.Layout}>
        <div className={styles.mastheadContainer}>
          <Header focusState={isFocus} focusMsg={focusMsg} />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </FocusContext.Provider>
  );
};

export default Layout;

// import React, { useContext, useState, useRef, useEffect } from "react";
import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import ediLogo from "../../edi-logo-transparent.png";
import styles from "./../header/header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import "./Switch.css";
import { logoutFetch } from "../login-page/helper";
import toastify from "../../toastify";
import { useNavigate } from "react-router-dom";
// import { Switch } from "./switch";
import { Menu } from "../components/menu";
// import { HiOutlineLightBulb } from "react-icons/hi";
import { ContactModal } from "../header/contact-modal";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
export const WatchPageHeader = ({
  lightsOn,
  setLightsOn,
  setIsChatbotOpen,
  videoTitle,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Retrieve the studyspaceId from the location state
  const { studyspaceId } = location.state || {};
  const [isMobile, setIsMobile] = useState(window.innerWidth < 980);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 980);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Back button to navigate to the previous studyspace
  const handleBackClick = () => {
    if (studyspaceId) {
      navigate(`/studyspace/${studyspaceId}`);
    }
  };

  // const toggleLightsOn = () => {
  //   setLightsOn(!lightsOn);
  // };

  // const isLoggedIn = localStorage.getItem("token") !== null;
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") !== null,
  );
  // // console.log(isLoggedIn)

  const logOut = async () => {
    try {
      const res = await logoutFetch(localStorage.getItem("token"));
      // console.log(res);
      if (res.status === 200) {
        localStorage.setItem("token", "DEMO_TOKEN");
        localStorage.setItem("name", "Not Logged In");
        navigate("/");
        //toastify.success("Logout successful");
        window.location.reload();
        setIsLoggedIn(false);
        // window.location.href = "/signin";
      } else {
        // const errorData = await res.json();
        toastify.error("Logout failed. Please try again.");
      }
    } catch (err) {
      toastify.error("Logout failed. Please try again.");
    }
  };

  const signIn = () => {
    localStorage.setItem("beforeSignInPage", window.location.pathname);
    // console.log(window.location.pathname);
    navigate("/signin");
  };

  return (
    <div className={lightsOn ? styles.header : styles["header-lightsoff"]}>
      <div className="px-4 mx-auto sm:px-6 lg:px-8 w-full">
        <nav className="flex items-center justify-between h-full w-full">
          <div className="flex items-center space-x-4">
            <div className="flex gap-2 items-center">
              {studyspaceId && <button
              onClick={handleBackClick}
              title="Back to Study Space"
              className="flex gap-1 justify-center items-center bg-transparent border-2 border-[#150940] rounded-lg cursor-pointer w-[36px] h-[32px] md:w-[70px] md:h-[30px] transition-all duration-200 hover:bg-gray-100 focus:bg-gray-100"
            >
              <FontAwesomeIcon icon={faLeftLong} className="" />
              {!isMobile && <span className="font-semibold">Back</span>}
            </button>}
            

              <Link to="/" title="Home" className="flex items-center ">
                <img
                  className="w-auto h-8 ml-1 md:h-10 mt-[2px]"
                  src={lightsOn ? ediLogo : ediLogo}
                  alt="Edi"
                />
              </Link>
            </div>
            {lightsOn && (
              <a
                href="https://discord.gg/4nSVGv8A3g"
                title=""
                rel="noopener noreferrer"
                className="items-center justify-center hidden px-2 py-2 text-xs font-bold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md lg:inline-flex hover:bg-blue-700 focus:bg-blue-700"
                role="button"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faDiscord}
                  style={{ marginRight: "8px" }}
                />
                Join Our Discord
              </a>
            )}
          </div>

          {/* Shows up when in mobile view */}
          <div className="md:hidden flex gap-2 items-center">
            {/* <div onClick={() => setIsChatbotOpen(true)} className="cursor-pointer">
              <HiOutlineLightBulb size={30} />
            </div> */}
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <ContactModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                />
              </div>
            )}
            {/* Center section: Video Title */}
            {/* Center Section: Video Title */}
            {videoTitle && (
              <div className="mx-2">
                <div
                  className="relative overflow-hidden w-full max-w-full text-ellipsis"
                  style={{ maxWidth: "30vw" }} // Adjust the max-width as needed
                >
                  <div
                    className={`${styles["scroll-text"]} whitespace-nowrap text-ellipsis`}
                  >
                    <h3 className="text-gray-800 font-bold text-md text-center text-ellipsis">
                      {videoTitle}
                    </h3>
                  </div>
                </div>
              </div>
            )}
            <a
              href="https://discord.gg/4nSVGv8A3g"
              title="Join our Discord"
              rel="noopener noreferrer"
              className="inline-flex justify-center items-center bg-transparent border-2 border-[#150940] rounded-lg cursor-pointer w-[42px] h-[34px] transition-all duration-200 hover:bg-gray-100 focus:bg-gray-100"
              role="button"
              target="_blank"
              id="discord-header-button"
            >
              <FontAwesomeIcon icon={faDiscord} />
            </a>
            <Menu
              logOut={logOut}
              isLoggedIn={isLoggedIn}
              signIn={signIn}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          </div>

          {/* Shows up when in desktop view */}
          <div className="hidden md:flex md:items-center md:ml-auto md:space-x-10">
            <Link
              to="/"
              title=""
              className={`${location.pathname === "/" ? `${styles.link} ${styles.active}` : styles.link} ${!lightsOn ? "hidden" : ""}`}
            >
              Home
            </Link>

            <Link
              to="/about"
              title=""
              className={`${location.pathname === "/about" ? `${styles.link} ${styles.active}` : styles.link} ${!lightsOn ? "hidden" : ""}`}
            >
              About Us
            </Link>

            <div className={styles.contactWrapper}>
              <button
                onClick={() => setIsModalOpen(!isModalOpen)}
                title="Contact Us"
                className={`${styles.link} ${isModalOpen ? styles.active : ""}`}
              >
                Contact Us
              </button>
              <ContactModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              />
            </div>

            {isLoggedIn && (
              <Menu logOut={logOut} isLoggedIn={isLoggedIn} signIn={signIn} />
            )}

            {!isLoggedIn && (
              <button
                title="Sign In to Access All Features"
                className={styles["unlock-feature"]}
                onClick={signIn}
              >
                <FontAwesomeIcon
                  icon={faArrowRightToBracket}
                  className="mr-2"
                />
                <strong>
                  <p>Sign In to Access All Features</p>
                </strong>
              </button>
            )}
            {/* <Switch toggleLightsOn={toggleLightsOn} lightsOn={lightsOn} /> */}
          </div>
        </nav>
      </div>
    </div>
  );
};

import styles from "./ChatButtonGroup.module.css";

export const ChatbotIcon = () => (
  <svg
    stroke-linejoin="round"
    stroke-linecap="round"
    stroke="#030c91"
    stroke-width="2"
    viewBox="0 0 24 24"
    height="25"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
    class={styles.svgIcon}
    fill="none"
  >
    <path fill="none" d="M0 0h24v24H0z" stroke="none"></path>
    <path d="M8 9h8"></path>
    <path d="M8 13h6"></path>
    <path
      d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z"
    ></path>
  </svg>
);

export const EditIcon = () => (
  <svg class={styles.svgIcon} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke="#a649da" stroke-linecap="round" stroke-width="2"><path d="m20 20h-16"></path><path clip-rule="evenodd" d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z" fill-rule="evenodd"></path></g></svg>
);

export const ListIcon = () => (
  <svg
    stroke="currentColor"
    stroke-width="2"
    viewBox="0 0 24 24"
    height="25"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <circle cx="5" cy="6" r="0.5" fill="currentColor"></circle>
    <circle cx="5" cy="12" r="0.5" fill="currentColor"></circle>
    <circle cx="5" cy="18" r="0.5" fill="currentColor"></circle>
    
    <line x1="9" y1="6" x2="19" y2="6" stroke="currentColor" stroke-width="2"></line>
    <line x1="9" y1="12" x2="19" y2="12" stroke="currentColor" stroke-width="2"></line>
    <line x1="9" y1="18" x2="19" y2="18" stroke="currentColor" stroke-width="2"></line>
  </svg>
);

export const IconsIcon = () => (
  <svg
  stroke="currentColor"
  stroke-width="2"
  viewBox="0 0 24 24"
  height="25"
  width="25"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
>
  <rect x="4" y="4" width="6" height="6" stroke="currentColor" stroke-width="2" />
  <rect x="14" y="4" width="6" height="6" stroke="currentColor" stroke-width="2" />
  <rect x="4" y="14" width="6" height="6" stroke="currentColor" stroke-width="2" />
  <rect x="14" y="14" width="6" height="6" stroke="currentColor" stroke-width="2" />
</svg>
);

export const ImportIcon = () => (
  <svg className={styles.svgIcon} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#a649da" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M12 3v12" />
      <path d="M8 11l4 4 4-4" />
      <path d="M4 20h16" />
    </g>
  </svg>
);

export const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" height="25" width="25">
    <path fill="#e50001" d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z" clip-rule="evenodd" fill-rule="evenodd"></path>
  </svg>
);

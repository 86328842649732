import React, { useState, useEffect } from "react";
import { Tabs } from "../../components/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faComments, faList } from "@fortawesome/free-solid-svg-icons";
import { Content } from './VideoContent';
import { useSwipeable } from "react-swipeable";
import toastify from "../../../toastify";

const MobileVideoInfo = ({
  currentSegment,
  currentSegmentIntro,
  segmentsLength,
  setCurrentSegmentIndex,
  currentSegmentIndex,
  setIsTimelineModalOpen,
  setIsChatbotOpen,
  currentSegmentStart,
  startOnboarding,
  isExpanded,
  setIsExpanded,
  setShowVideoPopup,
  setVideoInfos,
  videoDuration,
  segments,
}) => {
  const tabs = ["Content", "Transcripts"];
  const [selectedTab, setSelectedTab] = useState("Content");
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [contentItems, setContentItems] = useState([]);

  useEffect(() => {
    if (!currentSegment) {
      return;
    }

    const items = [
      { type: "Summary", data: currentSegment?.summary },
      ...(currentSegment?.goals || []).map((goal) => ({
        type: "Goal",
        data: goal,
      })),
    ];

    // Add a starting card only if the current segment index is 0
  if (currentSegmentIndex === 0) {
    items.unshift({
      type: "Starting",
      data: "Welcome to the first section!",
    });
  }
  console.log(currentSegmentIndex)
    setContentItems(items);
  }, [currentSegment]);

  // Function to toggle between expanded and collapsed states
  // const toggleModal = () => {
  //   setIsExpanded(!isExpanded);
  // };

  // Handle Next action
  const handleNext = () => {
    if (selectedTab === "Content") {
      if (currentContentIndex < contentItems.length - 1) {
        setCurrentContentIndex((prevIndex) => prevIndex + 1);
      } else {
        // At the end of current segment's content, move to next segment
        if (currentSegmentIndex < segmentsLength - 1) {
          setCurrentSegmentIndex((prevIndex) => prevIndex + 1);
          setCurrentContentIndex(0);
        } else {
          toastify.custom("You've completed the video. Great job!")
        }
      }
    }
  };

  // Handle Prev action
  const handlePrev = () => {
    if (selectedTab === "Content") {
      if (currentContentIndex > 0) {
        setCurrentContentIndex((prevIndex) => prevIndex - 1);
      } else {
        // At the beginning of current segment's content, move to previous segment
        if (currentSegmentIndex > 0) {
          setCurrentSegmentIndex((prevIndex) => prevIndex - 1);
          const prevSegment = segments[currentSegmentIndex - 1];
          const prevContentItems = [
            { type: "Summary", data: prevSegment.summary },
            ...(prevSegment.goals || []).map((goal) => ({
              type: "Goal",
              data: goal,
            })),
          ];
          setCurrentContentIndex(prevContentItems.length - 1);
        } else {
          console.log("You are at the beginning of the content.");
        }
      }
    }
  };

  // Reset currentContentIndex when currentSegment changes
  useEffect(() => {
    setCurrentContentIndex(0);
  }, [currentSegment]);

  // Get current segment start time in seconds
  const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const segmentStartInSeconds = timeToSeconds(currentSegmentStart);
  const videoDurationInSeconds = videoDuration;

  // Swipe handlers
  const handlers = useSwipeable({
    onSwipedUp: () => {
      handleNext();
    },
    onSwipedDown: () => {
      handlePrev();
    },
    delta: 50,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false,
  });

  if (!currentSegment) {
    return <EmptyInfo />;
  }
  
  const summaryItem = contentItems.find(
    (item) =>
      item.type === "Summary" &&
      item.data.toLowerCase() === "no summary available for this section."
  );

  return (
    <div
      {...handlers}
      id="video-info-container"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        backgroundColor: "white",
      }}
    >
      <div id="video-info" className="w-full h-full relative">
        {/* Tabs */}
        <Tabs
          tabs={tabs}
          selected={selectedTab}
          setSelected={setSelectedTab}
          className={"hidden md:flex"}
        />

        {/* Mobile Start Time and Title */}
        <div
          className="md:hidden fixed w-[100dvw] rounded-b-lg rounded-t-sm bg-white overflow-hidden z-40"
          id="sectionTitle"
          style={{
            border: "1px solid #d5d9e8",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          onClick={() => {setIsTimelineModalOpen(true)}}
        >
          {/* Progress Bar */}
          <div className="relative w-full h-1 bg-gray-300 rounded-full">
            <div
              className="absolute top-0 left-0 h-full bg-blue-500 rounded-full transition-all duration-300 ease-in-out"
              style={{
                width: `${
                  (segmentStartInSeconds / videoDurationInSeconds) * 100
                }%`,
              }}
            />
          </div>
          <h4 className="text-gray-700 font-semibold text-sm mt-1 mb-0 text-center">
            {currentSegmentIntro}
          </h4>

          <small className="block text-center text-gray-500 text-[10px] mb-1">
            {summaryItem ? 0 : contentItems.length} cards in this section
          </small>
        </div>

        {/* Content */}
        <div className="flex flex-col items-center justify-center">
          <Content
            total_steps={contentItems.length}
            currentSegment={currentSegment}
            selected={selectedTab}
            setSelected={setSelectedTab}
            currentContentIndex={currentContentIndex}
            contentItems={contentItems}
            setVideoInfos={setVideoInfos}
            setShowVideoPopup={setShowVideoPopup}
          />
        </div>

        {/* Floating Buttons */}
        <div className="fixed bottom-4 right-4 flex flex-col space-y-3 z-10">
          {/* Chatbot Button */}
          <button
            onClick={() => setIsChatbotOpen(true)}
            className="bg-blue-500 text-white p-3 rounded-full shadow-lg"
            aria-label="Open Chatbot"
          >
            <FontAwesomeIcon icon={faComments} size="lg" />
          </button>

          {/* Timeline Button */}
          <button
            onClick={() => setIsTimelineModalOpen(true)}
            className="bg-blue-500 text-white p-3 rounded-full shadow-lg"
            aria-label="Open Timeline"
          >
            <FontAwesomeIcon icon={faList} size="lg" />
          </button>

          {/* Info Button */}
          <button
            onClick={() => startOnboarding()}
            className="bg-blue-500 text-white p-3 rounded-full shadow-lg"
            aria-label="Start Onboarding"
          >
            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
          </button>
        </div>
      </div>
    </div>
  );
};

// EmptyInfo component will be rendered when there is no currentSegment
const EmptyInfo = () => {
  return (
    <div
      id="video-info-container"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        backgroundColor: "white",
      }}
    >
      <div id="video-info" className="w-full h-full"></div>
    </div>
  );
};

export default MobileVideoInfo;

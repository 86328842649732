import { useState, useContext, useRef } from "react";
import { VideoModalContext } from "./VideoModalContext";
import { useNavigate } from "react-router-dom";
import { CreditsContext } from "./header/CreditsContext";
import toastify from "../toastify";
import { extractYouTubeID } from "./watch-page/helper";
import { FaYoutube } from "react-icons/fa"; // Import YouTube icon from react-icons
import { MdSchool } from "react-icons/md"; // Example: Echo360 icon can be a school icon
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./generateVideoModal.module.css";
import echoGif from './Echo360_gif_zoom_in.gif'; // Adjust the path if necessary

export const GenerateVideoModal = ({
  setShowPopup,
  pollingCounterRef,
  fetchVideoList,
}) => {
  const { fetchCredits } = useContext(CreditsContext);
  const { generateVideoOpen, toggleGenerateVideo } =
    useContext(VideoModalContext);
  const navigate = useNavigate();

  const [youtubeURL, setYoutubeURL] = useState("");
  const [transcriptFile, setTranscriptFile] = useState(null);
  const [loadingURL, setLoadingURL] = useState(false); // Loading state for YouTube URL
  const [loadingTranscript, setLoadingTranscript] = useState(false); // Loading state for transcript
  const [isTooltipOpen, setIsTooltipOpen] = useState(false); // State to control tooltip

  const token = localStorage.getItem("token");

  const videoServerUrl =
    process.env.REACT_APP_VIDEO_PROCESS_SERVER_URL ||
    process.env.VIDEO_PROCESS_SERVER_URL;

  const fileInputRef = useRef(null); // Reference for the hidden file input

  // Toggle tooltip visibility
  const toggleTooltip = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleURLChange = (event) => {
    setYoutubeURL(event.target.value);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.name.endsWith(".vtt")) {
      setTranscriptFile(file);
      //toastify.success("Transcript file successfully added!");
    } else {
      toastify.error("Please upload a valid VTT file.");
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click(); // Simulate a click on the hidden file input when the area is clicked
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".vtt")) {
      setTranscriptFile(file);
      // toastify.success("Transcript file successfully added!");
    } else {
      toastify.error("Please upload a valid VTT file.");
    }
  };

  const handleFileUpload = async () => {
    if (!transcriptFile) {
      toastify.error("No file selected.");
      return;
    }
    try {
      const formData = new FormData();

      const title = transcriptFile.name.replace(/\.vtt$/, "");

      formData.append("file", transcriptFile);
      formData.append("title", title); // Use the file name without .vtt as the title
      formData.append("description", "");

      setLoadingTranscript(true); // Start loading for transcript upload
      const response = await fetch(`${videoServerUrl}/submit_transcript`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      setLoadingTranscript(false); // Stop loading for transcript upload

      if (response.status === 202 || response.status === 200) {
        toastify.success("Transcript uploaded successfully!");
        fetchVideoList();
        fetchCredits();

        if (pollingCounterRef) {
          pollingCounterRef.current = 0;
        }

        if (data.personal_studyspace_id) {
          navigate(`/studyspace/${data.personal_studyspace_id}`);
          toggleGenerateVideo();
          fetchVideoList();
        }
      } else if (response.status === 401) {
        localStorage.setItem("token", "DEMO_TOKEN"); // logout process
        navigate("/signin");
      } else {
        toastify.error(`${data.detail}`);
      }
    } catch (error) {
      setLoadingTranscript(false); // Stop loading on error
      toastify.error(
        "An error occurred during file upload! Please try again later.",
      );
    }
  };

  const handleSubmitURL = async () => {
    if (!token) {
      toastify.error("Please sign in first!");
      navigate("/signin");
      return;
    }

    const videoId = extractYouTubeID(youtubeURL);
    if (!videoId) {
      toastify.error("Please enter a valid YouTube URL!");
      return;
    }
    try {
      setLoadingURL(true); // Start loading for URL submission
      const response = await fetch(`${videoServerUrl}/submit_video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ video_id: videoId }),
      });

      const data = await response.json();
      setLoadingURL(false); // Stop loading for URL submission

      if (response.status === 202 || response.status === 200) {
        toastify.custom(
          `Video successfully submitted! It will appear in your personal studyspace.`,
        );
        fetchCredits();
        setYoutubeURL("");
        if (pollingCounterRef) {
          pollingCounterRef.current = 0;
        }

        if (data.personal_studyspace_id) {
          navigate(`/studyspace/${data.personal_studyspace_id}`);
          toggleGenerateVideo();
          fetchVideoList();
        }
      } else if (response.status === 401) {
        localStorage.setItem("token", "DEMO_TOKEN"); // logout process
        navigate("/signin");
      } else {
        toastify.error(`${data.detail}`);
      }
    } catch (err) {
      setLoadingURL(false); // Stop loading on error
      toastify.error(`Failed to submit video! Please try again later.`);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-3xl mx-auto py-8 px-4 sm:px-6">
      {/* ✅ Clean Heading */}
      <h1 className="text-2xl sm:text-3xl font-semibold text-gray-900 text-center mb-4 sm:mb-6">
        What do you want to learn today?
      </h1>
  
      {/* ✅ Main Input Box */}
      <div className="bg-white border border-gray-300 rounded-lg w-full shadow-sm px-4 sm:px-6 py-4 sm:py-5 space-y-4 relative">
        
        {/* 🎥 YouTube URL Submission */}
        <div className="flex flex-col sm:flex-row items-center w-full bg-gray-100 border border-gray-300 rounded-lg px-4 py-3 space-y-3 sm:space-y-0 sm:space-x-3">
          <FaYoutube className="text-red-600 text-xl" />
          <input
            type="text"
            placeholder="Paste YouTube link here"
            value={youtubeURL}
            onChange={(e) => setYoutubeURL(e.target.value)}
            className="flex-grow bg-transparent text-gray-700 placeholder-gray-500 focus:outline-none w-full sm:w-auto"
            disabled={loadingURL}
          />
          <button
            className={`w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition ${
              loadingURL ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleSubmitURL}
            disabled={loadingURL}
          >
            {loadingURL ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
          </button>
        </div>
  
        {/* Separator Line */}
        <div className="relative flex items-center justify-center">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-2 text-sm text-gray-500">or</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
  
        {/* 📜 Echo360 Transcript Upload */}
        <div className="w-full">
          <h3 className="text-lg font-medium text-gray-700 mb-2 flex items-center">
            <MdSchool className="text-blue-500 text-xl mr-2" />
            Upload Echo360 Transcript
          </h3>
  
          <div
            onClick={() => fileInputRef.current.click()}
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            className="w-full h-24 sm:h-28 border-2 border-dashed border-gray-400 rounded-lg flex justify-center items-center text-gray-600 cursor-pointer hover:bg-gray-200 text-center px-3"
          >
            {transcriptFile ? (
              <p>{transcriptFile.name} uploaded.</p>
            ) : (
              <p>Drag & drop your .vtt transcript here, or tap to upload</p>
            )}
          </div>
  
          {/* Hidden File Input */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept=".vtt"
          />
  
          <button
            className={`w-full bg-blue-600 text-white font-semibold py-2 rounded-lg mt-3 sm:mt-4 hover:bg-blue-700 transition ${
              loadingTranscript ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleFileUpload}
            disabled={loadingTranscript}
          >
            {loadingTranscript ? <FontAwesomeIcon icon={faSpinner} spin /> : "Upload Transcript"}
          </button>
        </div>
  
        {/* 📘 Help - How to download transcript from Echo360 */}
        <div className="text-center mt-4 sm:mt-6 relative">
          {/* ✅ Echo360 Demo GIF (Layered on Top) */}
          {isTooltipOpen && (
            <div className="absolute top-10 left-1/2 transform -translate-x-1/2 bg-white border border-gray-300 rounded-lg shadow-lg w-[90vw] md:w-[600px] z-50 p-4">
              <button
                className="absolute top-2 right-2 text-gray-600 bg-gray-300 rounded-full w-6 h-6 flex justify-center items-center hover:bg-gray-400 focus:outline-none"
                onClick={toggleTooltip}
              >
                ✕
              </button>
              <img
                src={echoGif}
                alt="How to download transcript from Echo360"
                className="w-full rounded-md"
              />
            </div>
          )}
  
          {/* ℹ️ Help Text (Underlined) */}
          <p
            className="text-sm text-gray-500 underline cursor-pointer"
            onClick={toggleTooltip}
          >
            Don’t know how to download the transcript from Echo360?
          </p>
        </div>
      </div>
    </div>
  );
  
  
  
};

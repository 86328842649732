/**
 * Performs authenticated HTTP requests to a specified path.
 *
 * @param {string} method - The HTTP method.
 * @param {string} path - The URL path to send the request to.
 * @param {string|null} token - The authentication token, if applicable.
 * @param {Object|null} body - The body of the request, for non-GET requests.
 * @returns {Promise<{ data: any, status: number }>} The data received from the request and the status code.
 */
const authFetch = async (method, path, token = null, body = null) => {
  const apiUrl = process.env.REACT_APP_API_URL || process.env.API_URL;
  const headers = {
    "Content-Type": "application/json",
  };

  if (token) headers["token"] = token;
  // if (token) headers.Authorization = `Bearer ${token}`;

  const fetchOptions = {
    method,
    headers,
  };

  // Add body to the request for non-GET methods
  if (body && method !== "GET") fetchOptions.body = JSON.stringify(body);

  try {
    // Perform the fetch request
    const response = await fetch(`${apiUrl}/${path}`, fetchOptions);

    // Parse the response data, if possible
    let data;
    try {
      data = await response.json(); // Try to parse JSON
    } catch (parseError) {
      data = null; // If parsing fails, set data to null
    }

    // Return both data and status code
    return {
      data,
      status: response.status,
    };
  } catch (error) {
    // Handle network or other errors
    console.error("Fetch error: ", error);

    return {
      data: null,
      status: 500, // Assuming 500 for network errors
    };
  }
};

export const getAnswerInStudyspace = async (
  history,
  query,
  studyspaceId,
  config,
  token,
) => {
  const path = `generate-answer-studyspace/${studyspaceId}`;
  const body = {
    history: history,
    query: query,
    config: config,
  };
  return await authFetch("POST", path, token, body);
};

export const secondsToTime = (totalSeconds) => {
  // Convert to number if input is a string
  if (typeof totalSeconds === "string") {
    totalSeconds = parseInt(totalSeconds, 10);
    if (isNaN(totalSeconds)) {
      return "Invalid input"; // Return error message if conversion fails
    }
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Padding the hours, minutes, and seconds with leading zeros if they are less than 10
  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = seconds.toString().padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

export const getExistStudySpaceList = async (token) => {
  const path = `studyspace/list-available`;
  return await authFetch("GET", path, token);
}

export const moveVideo = async (videoList, fromStudyspaceId, toStudyspaceId, isDuplicate, token) => {
  const path = `studyspace/${fromStudyspaceId}/move-videos/to/${toStudyspaceId}?duplicate=${isDuplicate}`;

  const body = {
    video_ids: videoList,
  };

  try {
    const response = await authFetch("POST", path, token, body);
    return response;
  } catch (err) {
    console.error("Move video failed:", err);
    return {
      data: null,
      status: 500,
    };
  }
};

export const deleteVideo = async (videoList, studyspaceId, token) => {
  const path = `studyspace/${studyspaceId}/videos`;
  const body = {
    video_ids: videoList,
  };

  try {
    const response = await authFetch("DELETE", path, token, body);
    return response; // { status, data }
  } catch (err) {
    console.error("Delete video failed:", err);
    return {
      data: null,
      status: 500,
    };
  }
};

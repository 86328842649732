import React, { useEffect, useState, useContext, useRef } from "react";
import { FocusContext } from "../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { getVideoListFromStudyspace, extractYouTubeID } from "../watch-page/helper";
import styles from "./Studyspace.module.css";
import Popup from "./Popup";
import { VideoModalContext } from "../VideoModalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faSpinner } from "@fortawesome/free-solid-svg-icons";
import StudyspaceChatbotModal from "./StudyspaceChatbotModal";
import AddToModal from "./modals/AddToModal";
import DeleteModal from "./modals/DeleteModal";
import { dotSpinner } from "ldrs";
import toastify from "../../toastify";
import { CreditsContext } from "../header/CreditsContext";
import { CreditModalContext } from "../header/CreditModalContext";
import { StudyspaceTour } from "./StudyspaceTour";
import VideoCard from "./components/VideoCard";
import VideoInfoPanel from "./components/VideoInfoPanel";
import TopButtonGroup from "./components/TopButtonGroup";

dotSpinner.register();

const YOUTUBE_API_KEY =
  process.env.REACT_APP_YOUTUBE_API_KEY || process.env.YOUTUBE_API_KEY;

export const fetchYouTubeDetails = async (video) => {
  const videoId = extractYouTubeID(video.link);
  const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${YOUTUBE_API_KEY}`;
  const response = await fetch(url);
  const data = await response.json();
  if (data.items.length > 0) {
    const snippet = data.items[0].snippet;
    return {
      ...video,
      thumbnailUrl: snippet.thumbnails.high.url,
      title: snippet.title,
      author: snippet.channelTitle,
      publishDate: snippet.publishedAt, // Fetch the publish date
    };
  }
  throw new Error("Video not found");
};

export const Studyspace = () => {
  const { fetchCredits } = useContext(CreditsContext);
  const { creditModalOpen, setCreditModalOpen } =
    useContext(CreditModalContext);
  const navigate = useNavigate();
  const [deletingVideo, setDeletingVideo] = useState(null);
  const [retryingVideo, setRetryingVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [progress, setProgress] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [youtubeURL, setYoutubeURL] = useState("");
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Add state for search input
  const [isEditing, setIsEditing] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showAddToModal, setShowAddToModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  const [showPopup, setShowPopup] = useState(false);
  const [popupConfig, setPopupConfig] = useState({
    message: "",
    primaryLabel: "",
    secondaryLabel: "",
    onPrimaryAction: null,
    onSecondaryAction: null,
  });
  const { studyspaceId } = useParams(); // Get the studyspace_id from the URL
  const [studyspaceName, setStudyspaceName] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isVideoInfoOpen, setIsVideoInfoOpen] = useState(false); // be used to ctrl info page
  // const [isTitleInView, setIsTitleInView] = useState(true);
  const containerRef = useRef(null);

  const { generateVideoOpen, toggleGenerateVideo } =
    useContext(VideoModalContext);

  const serverUrl = process.env.REACT_APP_API_URL || process.env.API_URL;
  const videoServerUrl =
    process.env.REACT_APP_VIDEO_PROCESS_SERVER_URL ||
    process.env.VIDEO_PROCESS_SERVER_URL;

  const openRetryPopup = (videoLink) => {
    setPopupConfig({
      message:
        "Ready to give it another shot? Retrying will cost 1 free credit. Proceed?",
      primaryLabel: "Yes, Retry",
      secondaryLabel: "No, Cancel",
      onPrimaryAction: () => {
        // console.log("Retry clicked");
        setShowPopup(false);
        retryVideo(videoLink);
      },
      onSecondaryAction: () => {
        // console.log("Cancel clicked");
        setShowPopup(false);
      },
    });
    setShowPopup(true);
  };

  const hasNotifiedRef = React.useRef(false);
  const isAnyProcessingRef = React.useRef(false);
  const pollingCounterRef = React.useRef(0); // Reference to track polling attempts
  const maxPollingAttempts = 33; // Maximum number of polling attempts

  const token = localStorage.getItem("token");
  const demoChatbotUsageLeft = localStorage.getItem("demoChatbotUsageLeft");

  // console.log(progress);
  const closeEditing = () => {
    setIsEditing(false);
    setSelectedVideos([]);
  }

  const fetchVideoList = async () => {
    try {
      const result = await getVideoListFromStudyspace(studyspaceId, token);
      // console.log(result);
      const list = result.data;
      setStudyspaceName(list.studyspacename);
      setFocusMsg(list.studyspacename);

      const videoDetailsResults = await Promise.allSettled(
        // extract success video, aviod dispplay issue
        list.videos.map(async (video) => {
          if (video.source === "youtube") {
            const youtubeDetails = await fetchYouTubeDetails(video);
            // Merge attributes, prioritizing existing `video.title` if present
            return {
              ...youtubeDetails,
              ...video, // Add existing video attributes
              title: video.title && video.title.trim() !== "" ? video.title : youtubeDetails.title,
            };
          } else {
            // Return the transcript video object directly
            return video;
          }
        })
      );
      // console.log(videoDetailsResults);
      // Sort videos by publish date
      // Filter out the fulfilled promises and extract the value
      const fulfilledVideoDetails = videoDetailsResults
        .filter((result) => result.status === "fulfilled")
        .map((result) => result.value);

      // console.log(fulfilledVideoDetails);
      fulfilledVideoDetails.sort(
        (a, b) => new Date(a.publishDate) - new Date(b.publishDate),
      );
      setVideos(fulfilledVideoDetails);
      setFilteredVideos(fulfilledVideoDetails); // Set initial filtered videos to all videos

      // Initialize progress state
      const initialProgress = {};
      fulfilledVideoDetails.forEach((video) => {
        initialProgress[video._id] = {
          progress: video.progress,
          status: video.status,
        };
      });
      setProgress(initialProgress);

      // Update processing state flag
      isAnyProcessingRef.current = fulfilledVideoDetails.some(
        (video) =>
          video.status === "processing" ||
          video.status === "pending" ||
          video.status === "retrying",
      );
    } catch (err) {
      console.error("Failed to fetch video data:", err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const { isFocus, setIsFocus, focusMsg, setFocusMsg } = useContext(FocusContext);
  useEffect(() => {
    const handleScroll = () => {
      // when user is scrolling, check if the title is in view
      const titleElement = document.getElementById("studySpaceTitle");
      if (titleElement) {
        const rect = titleElement.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
  
        if (isInView) {
          // title in view
          setIsFocus(false);
        } else if (!isInView) {
          // title out of view
          setIsFocus(true);
        }
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  
  useEffect(() => {
    fetchVideoList();
    const interval = setInterval(async () => {
      // console.log(document.hidden);
      if (
        isAnyProcessingRef.current &&
        pollingCounterRef.current < maxPollingAttempts &&
        !document.hidden
      ) {
        // Only poll if there was any video processing last time
        try {
          const result = await getVideoListFromStudyspace(studyspaceId, token);
          const list = result.data;

          const updatedProgress = {};
          list.videos.forEach((video) => {
            updatedProgress[video._id] = {
              progress: video.progress,
              status: video.status,
            };
          });
          setProgress(updatedProgress);

          // Update processing state flag
          isAnyProcessingRef.current = list.videos.some(
            (video) =>
              video.status === "processing" ||
              video.status === "pending" ||
              video.status === "retrying",
          );

          pollingCounterRef.current += 1;
        } catch (err) {
          console.error("Failed to fetch updated progress:", err);
        }
      } else if (
        pollingCounterRef.current >= maxPollingAttempts &&
        !hasNotifiedRef.current
      ) {
        // Show toast notification once when the polling limit is reached
        toastify.error(
          "Polling limit reached. Please refresh the page to get more updates.",
        );
        hasNotifiedRef.current = true; // Set to true to avoid multiple notifications
      }
    }, 15000); // Poll every 10 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [studyspaceId]);

  const handleURLChange = (event) => {
    setYoutubeURL(event.target.value);
  };

  // Function to delete a video
  const deleteVideo = async (videoId) => {
    setDeletingVideo(videoId); // Set the deleting video state
    try {
      const response = await fetch(`${serverUrl}/video/${videoId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        toastify.success("Video deleted successfully");
        // Refetch video list after deletion
        fetchVideoList();
      } else {
        const data = await response.json();
        toastify.error(data.detail || "Failed to delete video");
      }
      setDeletingVideo(null); // Clear deleting state
    } catch (error) {
      setDeletingVideo(null); // Clear deleting state
      toastify.error("An error occurred while deleting the video");
    }
  };

  const retryVideo = async (videoLink) => {
    setRetryingVideo(videoLink);

    const videoServerUrl =
      process.env.REACT_APP_VIDEO_PROCESS_SERVER_URL ||
      process.env.VIDEO_PROCESS_SERVER_URL;

    if (!token) {
      toastify.error("Please sign in first!");
      navigate("/signin");
    }

    try {
      const videoId = extractYouTubeID(videoLink);
      const response = await fetch(`${videoServerUrl}/submit_video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ video_id: videoId }),
      });

      const data = await response.json();

      if (response.status === 202 || response.status === 200) {
        toastify.custom(`Video successfully submitted!`);

        fetchCredits(); // update credit number on header

        setYoutubeURL("");
        if (pollingCounterRef) {
          pollingCounterRef.current = 0;
        }

        // Navigate to the studyspace using the `studyspace_id`
        if (data.personal_studyspace_id) {
          navigate(`/studyspace/${data.personal_studyspace_id}`);

          // Call the function to refetch the video list
          fetchVideoList();
        }
      } else if (response.status === 401) {
        localStorage.setItem("token", "DEMO_TOKEN"); // logout process
        navigate("/signin");
      } else if (response.status === 400) {
        toastify.error(`${data.detail}`);
        setCreditModalOpen(true);
      } else {
        toastify.error(`${data.detail}`);
      }
      setRetryingVideo(null); // Clear retrying state
    } catch (err) {
      setRetryingVideo(null); // Clear retrying state
      toastify.error(`Failed to submit video! Please try again later.`);
    }
  };

  const renderProgressBar = (videoId, videoLink, source) => {
    const videoProgress = progress[videoId];
    if (!videoProgress) return null;

    if (videoProgress.status === "failed" && source === "transcript") {
      return (
        <div className={styles.retryContainer}>
          <button
            className={styles.retryButton}
            onClick={() => deleteVideo(videoId)}
            disabled={deletingVideo === videoId} // Disable while deleting
          >
            {deletingVideo === videoId ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Delete"
            )}
          </button>
          <p className={styles.failedText}>Transcript Upload Failed</p>
        </div>
      );
    }

    if (videoProgress.status === "failed" && source === "youtube") {
      return (
        <div className={styles.retryContainer}>
          <button
            className={styles.retryButton}
            onClick={() => retryVideo(videoLink)}
            disabled={retryingVideo === videoLink} // Disable while retrying
          >
            {retryingVideo === videoLink ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Retry"
            )}
          </button>
          <p className={styles.failedText}>Video Upload Failed</p>
        </div>
      );
    }

    if (videoProgress.status === "retrying") {
      return (
        <div className={styles.progressContainer}>
          <p className={styles.progressText}>Attempting Retry...</p>
        </div>
      );
    }

    if (videoProgress.status === "pending") {
      return (
        <div className={styles.progressContainer}>
          <p className={styles.progressText}>Pending...</p>
        </div>
      );
    }

    if (videoProgress.status === "completed") return null;

    return (
      <div className={styles.progressBarContainer}>
        <div
          className={styles.progressBar}
          style={{ width: `${videoProgress.progress}%` }}
        ></div>
        <p className={styles.progressText}>
          {videoProgress.status} ({videoProgress.progress}%)
        </p>
      </div>
    );
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);

    const filtered = videos.filter((video) =>
      video.title.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setFilteredVideos(filtered);
  };

  // console.log(filteredVideos);

  // video info button
  const handleInfoButtonClick = (video) => {
    setSelectedVideo(video); // the relative video when info button was clicked
    setIsVideoInfoOpen(true); // open video info display
  };
  
  const handleCloseInfo = () => {
    setIsVideoInfoOpen(false); // close video info display
  };

  const handleSelectAll = () => {
    if (selectedVideos.length === videos.length) {
      setSelectedVideos([]); // if already select all, deselect all
      setIsSelectAll(false);
    } else {
      setSelectedVideos(videos.map(video => video._id)); // select all
      setIsSelectAll(true);
    }
  };

  const handleVideoSelect = (videoId) => {
    setSelectedVideos((prevSelectedVideos) => {
      // if the video is already selected
      if (prevSelectedVideos.includes(videoId)) {
        return prevSelectedVideos.filter((id) => id !== videoId);
      } else {
        // add the video to the selected list
        return [...prevSelectedVideos, videoId];
      }
    });
  };

  useEffect(() => {
    if (!isEditing) setSelectedVideos([]);
  }, [isEditing]);

  useEffect(() => { 
    console.log(selectedVideos);
  }, [selectedVideos]);

  return (
    <>
      {showAddToModal && (
        <AddToModal
          onClose={() => setShowAddToModal(false)}
          onExitEdit={() => closeEditing()}
          studyspaceId={studyspaceId}
          selectedVideos={selectedVideos}
          setIsLoading={setIsLoading}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onClose={() => setShowDeleteModal(false)}
          onExitEdit={() => closeEditing()}
          studyspaceId={studyspaceId}
          selectedVideos={selectedVideos}
          setIsLoading={setIsLoading}
          refreshVideos={fetchVideoList}
        />
      )}
      {isChatbotOpen && (
        <StudyspaceChatbotModal
          setIsChatbotOpen={setIsChatbotOpen}
          _id={studyspaceId}
        />
      )}
      {showPopup && (
        <Popup
          message={popupConfig.message}
          primaryLabel={popupConfig.primaryLabel}
          secondaryLabel={popupConfig.secondaryLabel}
          onPrimaryAction={popupConfig.onPrimaryAction}
          onSecondaryAction={popupConfig.onSecondaryAction}
        />
      )}
        {/* {(
          <GenerateVideoModal
            setShowPopup={setShowPopup}
            setPopupConfig={setPopupConfig}
            pollingCounterRef={pollingCounterRef}
            fetchVideoList={fetchVideoList}
          />
        )} */}
        {isLoading && (
          <div className={styles.loader}>
            <l-dot-spinner size="40" speed="0.9" color="black"></l-dot-spinner>
          </div>
        )}

      {!isLoading && <StudyspaceTour />}

      <div
        className={`${isLoading ? "opacity-0" : "opacity-100"} transition-opacity duration-200 ease-in-out`}
        ref={containerRef} style={{ overflowY: "auto" }}
      >
        <div className={`${styles.studyspaceSection} ${isVideoInfoOpen ? styles.shrink : ""}`}>
          <h2 className={styles.sectionTitle} id="studySpaceTitle">
            {studyspaceName}
            <span className={styles.infoIcon}>
              <FontAwesomeIcon icon={faCircleInfo} />
              <span className={styles.tooltipText}>
                The name of the studyspace you are in
              </span>
            </span>
          </h2>

          <TopButtonGroup
            videos={videos}
            token={token}
            demoChatbotUsageLeft={demoChatbotUsageLeft}
            setIsChatbotOpen={setIsChatbotOpen}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            onSelectAll={handleSelectAll}
            selectedVideos={selectedVideos}
            onAddToClick={() => setShowAddToModal(true)}
            setShowDeleteModal={setShowDeleteModal}
            onExitEdit={() => closeEditing()}
          />


          {/* < div className={styles.searchContainer}>
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search videos by name..."
              value={searchQuery}
              onChange={handleSearchChange} // Update search input
            />
          </div> */}

          <div className={styles.studyspaceContainer}>
            <div className={styles.videoListContainer}>
              <div className={styles.videoList} id="studySpaceVideos">
                {filteredVideos.map((video, index) => (
                  <VideoCard
                    key={index}
                    video={video}
                    progress={progress}
                    studyspaceId={studyspaceId}
                    onRetryVideo={retryVideo}
                    onDeleteVideo={deleteVideo}
                    onInfoClick={handleInfoButtonClick}
                    isEditing={isEditing}
                    selectallActive={isSelectAll}
                    onSelectVideo={handleVideoSelect}
                    selectedVideos={selectedVideos}
                  />
                ))}
              </div>
            </div>

            <VideoInfoPanel
              isOpen={isVideoInfoOpen}
              video={selectedVideo}
              onClose={handleCloseInfo}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// DesktopContent.jsx

import React from "react";
import { NoSummaryCard } from "../../component/NoSummaryCard";
import { GoalCardDesktop } from "../../component/GoalCardDesktop";
import { SummaryCardDesktop } from "../../component/SummaryCardDesktop";
import { StartingCardDesktop } from "../../component/StartingCardDesktop";

export const DesktopContent = ({
  contentItems,
  currentContentIndex,
  total_steps,
  setIsTranscriptCollapsed,
  handleCardNext,
  rightTab,
  setRightTab,
  externalMessage,
  setExternalMessage,
}) => {
  const currentItem = contentItems[currentContentIndex];

  if (!currentItem) {
    return null;
  }

  // Function to handle click and open the chatbot with a predefined message
  const handleContentClick = () => {
    if (setIsTranscriptCollapsed && setRightTab && setExternalMessage) {
      setRightTab("chatbot");              // Switch to Chatbot tab
      setIsTranscriptCollapsed(false);     // Ensure the sidebar is expanded
      const goalTitle = currentItem.data.learning_goal;
      setExternalMessage(`Explain me "${goalTitle}" in-depth`); // Set external message
      // Ensure that sendMessage is NOT called directly here
    }
  };

  const { type, data } = currentItem;

  if (type === "Starting") {
    return (
      <div className="w-full flex justify-center mt-2">
        <div className="w-full max-w-3xl flex justify-center">
          <StartingCardDesktop />
        </div>
      </div>
    );
  }

  if (type === "Summary") {
    // if (
    //   !data ||
    //   data.toLowerCase() === "no summary available for this section."
    // ) {
    //   return (
    //     <div className="w-full flex justify-center mt-2">
    //       <div className="w-full max-w-3xl flex justify-center">
    //         <NoSummaryCard handleCardNext={handleCardNext} />
    //       </div>
    //     </div>
    //   );
    // }
    return (
      <div className="w-full max-h-[80%] flex justify-center mt-2">
        <div className="w-full max-w-3xl flex justify-center">
          <SummaryCardDesktop data={data} />
        </div>
      </div>
    );
  }

  if (type === "Goal") {
    const goal = data;
    return (
      <div
        className="w-full max-h-[80%] flex justify-center mt-2 hover:cursor-pointer"
        onClick={handleContentClick} // Updated onClick handler
      >
        <div className="w-full max-w-3xl flex justify-center">
          <GoalCardDesktop
            total_steps={total_steps}
            step_number={goal.step_number}
            learning_goal={goal.learning_goal}
            explanation={goal.explanation}
          />
        </div>
      </div>
    );
  }

  return null;
};

import React, { useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaVideo, FaRegImage } from "react-icons/fa";

const StudyspaceCard = forwardRef(({ studyspace, highlightId }, ref) => {
  const navigate = useNavigate();
  const [thumbnailError, setThumbnailError] = useState(false);

  return (
    <div
      ref={ref}
      className={`relative w-full max-w-[360px] bg-white rounded-2xl shadow-lg border border-gray-300 hover:shadow-xl hover:scale-[1.02] transition-all cursor-pointer overflow-hidden z-40 ${
        highlightId === studyspace._id ? "ring-2 ring-green-500" : ""
      }`}
      onClick={() => navigate(`/studyspace/${studyspace._id}`)}
    >
      <div className="relative h-[12rem] w-full flex items-center justify-center">
        <div className="absolute top-[1.3rem] left-0 w-full h-full bg-gray-300 rounded-xl transform scale-[0.90] opacity-40 shadow-lg"></div>
        <div className="absolute top-[0.9rem] left-0 w-full h-full bg-gray-200 rounded-xl transform scale-[0.93] opacity-60 shadow-md"></div>
        <div className="absolute top-[0.5rem] left-0 w-full h-full bg-gray-100 rounded-xl transform scale-[0.96] opacity-80 shadow"></div>

        {studyspace.thumbnailUrl && !thumbnailError ? (
          <img
            src={studyspace.thumbnailUrl}
            alt={studyspace.name}
            className="w-full h-full object-cover rounded-xl relative shadow-md"
            onError={() => setThumbnailError(true)}
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-100 rounded-xl text-gray-500 z-50">
            <FaRegImage className="text-6xl opacity-50" />
          </div>
        )}
      </div>

      <div className="p-4">
        <h3 className="text-lg font-semibold text-gray-900 truncate">
          {studyspace.name}
        </h3>
        <div className="flex items-center text-gray-600 text-sm mt-2">
          <FaVideo className="text-blue-500 mr-2" />
          <span>{studyspace.videos.length} Videos</span>
        </div>
      </div>

      {highlightId === studyspace._id && (
        <div className="absolute top-2 right-2 bg-green-500 text-white text-xs px-2 py-1 rounded shadow z-50">
          Just Created
        </div>
      )}
    </div>
  );
});

export default StudyspaceCard;

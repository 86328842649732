import React, { useEffect, useState } from "react";
import styles from "./AddToModal.module.css";
import { getToken } from "../../../utils/helper";
import { getExistStudySpaceList, moveVideo } from "../helper";
import { Link } from "react-router-dom";

const AddToModal = ({ onClose, onExitEdit, studyspaceId, selectedVideos, setIsLoading: triggerLoading }) => {
  const [studyspaces, setStudyspaces] = useState([]);
  const [selectedSpaceId, setSelectedSpaceId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null); // null | true | false

  useEffect(() => {
    const fetchSpaces = async () => {
      const token = getToken();
      try {
        const res = await getExistStudySpaceList(token);
        setStudyspaces(res.data.studyspaces || []);
      } catch (err) {
        console.error("Failed to load studyspaces", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSpaces();
  }, []);

  const handleChange = (e) => {
    setSelectedSpaceId(e.target.value);
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    const token = getToken();
    try {
      const res = await moveVideo(
        selectedVideos,
        studyspaceId,
        selectedSpaceId,
        true, // isDuplicate
        token
      );

      if (res.status === 200) {
        setSubmitSuccess(true);
      } else {
        console.error("Move failed:", res);
        setSubmitSuccess(false);
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setSubmitSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h2>Add to your study space</h2>

        {submitSuccess === null ? (
          <>
            <p>Please select a study space you'd like to add to:</p>

            <select
              value={selectedSpaceId}
              onChange={handleChange}
              className={styles.select}
              disabled={isLoading || studyspaces.length === 0 || isSubmitting}
            >
              {isLoading ? (
                <option>Loading...</option>
              ) : studyspaces.length === 0 ? (
                <option disabled>No available study spaces</option>
              ) : (
                <>
                  <option value="" disabled>
                    Select a study space
                  </option>
                  {studyspaces.map((space) => (
                    <option key={space.id} value={space.id}>
                      {space.name}
                    </option>
                  ))}
                </>
              )}
            </select>

            <div className={styles.buttonRow}>
              <button
                onClick={onClose}
                className={styles.closeButton}
                disabled={isSubmitting}
              >
                Close
              </button>

              <button
                onClick={handleConfirm}
                className={styles.confirmButton}
                disabled={!selectedSpaceId || isSubmitting}
              >
                {isSubmitting ? "Processing..." : "Confirm"}
              </button>
            </div>
          </>
        ) : (
          <>
            <p>
              {submitSuccess ? (
                <>
                  ✅ Videos successfully added to{" "}
                  <Link
                    to={`/studyspace/${selectedSpaceId}`}
                    onClick={() => {
                      onExitEdit();
                      onClose();
                      triggerLoading(true);
                    }}
                    className={styles.link}
                  >
                    {
                      studyspaces.find((s) => s.id === selectedSpaceId)?.name ||
                      "the selected study space"
                    }
                  </Link>
                  .
                </>
              ) : (
                "❌ Failed to move videos. Please try again later."
              )}
            </p>

            <div className={styles.buttonRow}>
                <button
                  onClick={() => {
                      onExitEdit();
                      onClose();
                  }}
                  className={styles.confirmButton}
                >
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddToModal;

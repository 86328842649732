import { useSetState } from "react-use";
import Joyride, { STATUS } from "react-joyride";
import { useEffect } from "react";
import Lottie from "react-lottie";
import * as animationData from "../../components/logos/animation-welcome.json";
// import ediLogo from "../../../edi-logo-transparent.png";

export const MobileWatchTour = ({
  setIsTimelineModalOpen,
  runOnboarding,
  setRunOnboarding,
  setIsExpanded,
  setCurrentSegmentIndex,
}) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (runOnboarding) {
      setState({ run: true });
      setRunOnboarding(false);
    }
  }, [runOnboarding]);

  // State for Joyride -- Tutorial
  const [{ run, steps }, setState] = useSetState({
    run: false,
    steps: [
      {
        content: (
          <div className="flex flex-col">
            <div className="flex text-center items-center justify-center">
              Let’s walk through the features to help you get started.
            </div>
            <small className="text-center text-gray-500 mt-2">
              This will only take ~30 seconds
            </small>
          </div>
        ),
        placement: "center",
        target: "body",
        title: (
          <div className="font-bold text-xl mt-4 flex items-center justify-center">
            <Lottie
              options={defaultOptions}
              height={40}
              width={40}
              style={{ margin: 0 }}
            />
            Welcome to the Video Page!
            <Lottie
              options={defaultOptions}
              height={40}
              width={40}
              style={{ margin: 0 }}
            />
          </div>
        ),
        locale: { skip: "Maybe Later" },
      },
      {
        content: (
          <h2>
            The video is broken into <strong>sections</strong> for easy
            navigation. Right now, you’re in the first section.
          </h2>
        ),
        placement: "auto",
        target: "#timeline",
        title: <b className="mt-4">Timeline</b>,
      },
      {
        content: (
          <h2>This area shows the <strong>progress bar</strong> of your current position in the video
          and the {" "}<strong>title of the current section</strong>.
          </h2>

        ),
        target: "#sectionTitle",
        title: <b className="mt-4">Section Title</b>,
      },
      {
        content: (
          <h2>
          Once you enter a new section, it will display its <strong>Summary Card</strong>, followed by <strong>Objective Cards</strong>.
          </h2>

        ),
        target: "#sectionContent",
        placement: "auto",
        title: <b className="mt-4">Section Cards</b>,
      },
      // {
      //   content: (
      //     <h2>
      //       You can view the <strong>section content</strong> in{" "}
      //       <strong>three different styles</strong>. Pick the one that works
      //       best for you!
      //     </h2>
      //   ),
      //   target: "#styleChooser",
      //   placement: "bottom",
      //   title: <b>Style Chooser</b>,
      // },
      {
        content: (
          <h2>
            Have any questions? Use the chatbot! It’s ready to assist you with
            answers about the <strong>video content</strong>.
          </h2>
        ),
        target: "#videoChatbot",
        placement: "bottom",
        title: <b>Video Chatbot</b>,
      },
      {
        content: (
          <div className="flex flex-col items-center gap-8">
            <h2>
              If you need more help, feel free to ask on our Discord server.
            </h2>
          </div>
        ),
        placement: "top",
        target: "#discord-header-button",
        title: (
          <div className="flex justify-center items-center font-bold">
            <Lottie
              options={defaultOptions}
              height={40}
              width={40}
              style={{ margin: 0 }}
            />
            Congrats! Video Page Tutorial Completed!{" "}
            <Lottie
              options={defaultOptions}
              height={40}
              width={40}
              style={{ margin: 0 }}
            />
          </div>
        ),
        locale: { last: "Got it!" },
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { status, index, action } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    console.log(data);

    // Set the timeline modal to open after the first step
    if (index === 0 && action === "start") {
      //setIsExpanded(false); // expand tool bar
      setIsTimelineModalOpen(true); // open timeline modal
      setCurrentSegmentIndex(0); // move to the first section
    }

    // Set the timeline modal to close after the first second step
    if (index === 2) {
      setIsTimelineModalOpen(false);
    }

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
    }
  };

  // Check if this is the first visit to the page
  // If it is, show the tutorial
  useEffect(() => {
    const isMobileWatchFirst = localStorage.getItem("isMobileWatchFirst");
    if (!isMobileWatchFirst) {
      localStorage.setItem("isMobileWatchFirst", "true");
      setState({ run: true });
    }
  }, []);

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={run}
      disableScrolling={true}
      showSkipButton
      steps={steps}
      styles={{
        options: {
          primaryColor: "#1976d2",
          zIndex: 1000,
        },
      }}
    />
  );
};

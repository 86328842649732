import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import Modal from "react-modal";
import toastify from "../../toastify";
import { authFetch } from "../watch-page/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const CreateStudyspaceCard = ({ onStudyspaceCreated }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [studyspaceName, setStudyspaceName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    if (!studyspaceName.trim()) {
      toastify.error("Please enter a valid studyspace name.");
      return;
    }

    setLoading(true);
    const token = localStorage.getItem("token");
    const { data, status } = await authFetch(
      "POST",
      "studyspace/create",
      token,
      { name: studyspaceName }
    );

    setLoading(false);

    if (status === 200 || status === 201) {
      toastify.success("Studyspace created!");
      setIsModalOpen(false);
      setStudyspaceName("");
      if (onStudyspaceCreated) onStudyspaceCreated(data.studyspace);
    } else {
      toastify.error(data?.detail || "Failed to create studyspace.");
    }
  };

  return (
    <>
      <div
        className="relative w-full max-w-[360px] bg-gray-50 rounded-2xl border-2 border-dashed border-gray-400 shadow-md flex flex-col justify-center items-center h-[18rem] cursor-pointer hover:bg-gray-100 transition-all"
        onClick={() => setIsModalOpen(true)}
      >
        <FaPlus className="text-gray-500 text-4xl" />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="w-full max-w-lg mx-4 sm:mx-auto mt-32 bg-white rounded-2xl p-6 sm:p-8 shadow-2xl outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-40 z-50 flex justify-center items-start sm:items-start pt-[10vh] sm:pt-[5vh] px-2"
      >
        <h2 className="text-xl sm:text-2xl font-bold mb-5 text-gray-800 text-center sm:text-left">
          Create a New Studyspace
        </h2>

        <input
          type="text"
          value={studyspaceName}
          onChange={(e) => setStudyspaceName(e.target.value)}
          className="w-full border border-gray-300 rounded-lg px-4 py-3 mb-6 text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter studyspace name"
          disabled={loading}
        />

        <div className="flex flex-col sm:flex-row justify-end gap-3">
          <button
            onClick={() => setIsModalOpen(false)}
            className="w-full sm:w-auto px-4 py-2 rounded-lg bg-gray-200 hover:bg-gray-300 text-gray-700"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={handleCreate}
            className={`w-[5rem] px-3 rounded-lg text-white font-medium flex items-center justify-center ${
              loading
                ? "bg-blue-500 cursor-not-allowed opacity-70"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
            disabled={loading}
          >
            {loading ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin className="" />
              </>
            ) : (
              "Create"
            )}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CreateStudyspaceCard;

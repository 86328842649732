import React, { useState, useRef, useEffect } from "react";
import { getAnswerInStudyspace } from "./helper"; // Adjusted for studyspace scope
import ReactMarkdown from "react-markdown";
import styles from "./StudyspaceChatbotModal.module.css";
import { HiOutlineLightBulb } from "react-icons/hi";
import { quantum } from "ldrs";
import { secondsToTime } from "./helper";
import { ModalWrapper } from "../components/modal-wrapper";
import { useNavigate } from "react-router-dom";

quantum.register();

const StudyspaceChatbotModal = ({ _id, setIsChatbotOpen }) => {
  const navigate = useNavigate();

  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true); // Track if the user is at the bottom
  const [config, setConfig] = useState(() => {
    return localStorage.getItem("config") || "moderate";
  });

  const textAreaRef = useRef(null);
  const chatHistoryRef = useRef(null);

  const handleTextareaFocus = () => setIsTextareaFocused(true);
  const handleTextareaBlur = () => setIsTextareaFocused(false);

  const token = localStorage.getItem("token");
  const isSignedIn = localStorage.getItem("token") !== "DEMO_TOKEN";

  useEffect(() => {
    if (textAreaRef.current) {
      adjustTextAreaHeight(textAreaRef.current);
    }
  }, [input]);

  useEffect(() => {
    localStorage.setItem("config", config);
  }, [config]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    adjustTextAreaHeight(e.target);
  };

  const adjustTextAreaHeight = (textArea) => {
    textArea.style.height = "auto";
    textArea.style.height =
      textArea.scrollHeight < 100 ? `${textArea.scrollHeight + 2}px` : "100px";
  };

  const sendMessage = async (input) => {
    if (!input || !input.trim() || isGenerating) return;
    setIsGenerating(true);

    const userMessage = { author: "user", text: input.trim() };
    setMessages((messages) => [...messages, userMessage]);
    setInput("");

    // Scroll to the bottom as soon as the message is sent
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }

    let history = messages.map((msg) => ({
      role: msg.author.toLowerCase(),
      content: msg.text,
    }));

    setMessages((messages) => [
      ...messages,
      { author: "assistant", text: "", isGenerating: true },
    ]);

    const res = await getAnswerInStudyspace(
      history,
      input.trim(),
      _id,
      config,
      token,
    );
    if (res && res.status === 200) {
      setMessages((messages) => [
        ...messages.slice(0, -1),
        { author: "assistant", text: res.data.answer, markdown: true },
      ]);

      // Decrement demo usage if in demo mode
      if (!isSignedIn) {
        let currentUsageLeft = localStorage.getItem("demoChatbotUsageLeft");
        if (currentUsageLeft) {
          currentUsageLeft = parseInt(currentUsageLeft, 10);
          if (currentUsageLeft > 0) {
            localStorage.setItem("demoChatbotUsageLeft", currentUsageLeft - 1);
          }
        }
      }
    } else if (res.status === 401) {
      navigate("/signin");
    } else {
      setMessages((messages) => [
        ...messages.slice(0, -1),
        {
          author: "assistant",
          text: "Unable to retrieve an answer, please try again later.",
        },
      ]);
    }

    // Scroll to the bottom when the response is finished
    if (chatHistoryRef.current && isAtBottom) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }

    setIsGenerating(false);
  };

  const handleScroll = () => {
    if (!chatHistoryRef.current) return;

    const threshold = 50; // Allow some tolerance, e.g., 50px from the bottom
    const isUserAtBottom =
      chatHistoryRef.current.scrollHeight - chatHistoryRef.current.scrollTop <=
      chatHistoryRef.current.clientHeight + threshold;

    setIsAtBottom(isUserAtBottom); // Track if user is near the bottom
  };

  useEffect(() => {
    const chatHistory = chatHistoryRef.current;

    if (chatHistory) {
      chatHistory.addEventListener("scroll", handleScroll);

      return () => chatHistory.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    if (chatHistoryRef.current && isAtBottom) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight; // Auto-scroll only if the user is at the bottom
    }
  }, [messages, isAtBottom]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage(input);
    }
  };

  const codeBlockStyle = {
    backgroundColor: "#000",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    overflowX: "auto",
  };

  const handleLinkClick = (event, href) => {
    event.preventDefault();
    if (href.startsWith("http://localhost:3000")) {
      href = href.replace("http://localhost:3000", "");
    }
    navigate(href);
  };

  return (
    <ModalWrapper setOpen={setIsChatbotOpen}>
      <div className={styles.chatHistory} ref={chatHistoryRef}>
        {messages.length === 0 ? (
          <div className={styles.emptyState}>
            <HiOutlineLightBulb size={30} />
            <h4 className={styles.headingStyle}>
              Ask me anything about this study space...
            </h4>
          </div>
        ) : (
          <>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`${msg.author === "user" ? styles.messageUser : styles.messageBot}`}
              >
                {msg.isGenerating ? (
                  <div className={styles.loadingIcon}>
                    <l-quantum size="30" speed="1.75" color="black"></l-quantum>
                  </div>
                ) : msg.markdown ? (
                  <ReactMarkdown
                    className="text-base leading-relaxed space-y-4"
                    components={{
                      a: ({ node, ...props }) => (
                        <a
                          {...props}
                          className="text-blue-500 cursor-pointer whitespace-nowrap"
                          onClick={(event) =>
                            handleLinkClick(event, props.href)
                          }
                        >
                          {props.children}
                        </a>
                      ),
                      pre: ({ node, children, ...props }) => (
                        <pre style={codeBlockStyle}>{children}</pre>
                      ),
                      li: ({ node, ...props }) => (
                        <li className="mb-4" {...props} />
                      ),
                    }}
                  >
                    {msg.text}
                  </ReactMarkdown>
                ) : (
                  <p>{msg.text}</p>
                )}
              </div>
            ))}
            <div style={{ height: "18%" }}></div>
          </>
        )}
      </div>

        {/* Show demo usage left if not signed in */}
      {!isSignedIn && (
        <div className="mt-0">
        <small className="text-gray-500 text-xs ml-3">
          Click {" "}
          <button 
          onClick={() => navigate('/signin')} 
          className="text-blue-500 underline hover:text-blue-700"
        >
          here
        </button>
        {" "}to sign in
        </small>
      </div>
      )}
      
      <div className={styles.inputContainer}>
      <textarea
        ref={textAreaRef}
        value={input}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        onFocus={handleTextareaFocus}
        onBlur={handleTextareaBlur}
        className={styles.textArea}
        placeholder={!isSignedIn ? "Please sign in to continue" : "Type your question here"}
        rows={1}
        disabled={!isSignedIn}
      />
      <button
        onClick={() => sendMessage(input)}
        className={styles.sendButton}
        disabled={!input.trim() || isGenerating || (!isSignedIn)}
      >
        {"Send"}
      </button>
      </div>
    </ModalWrapper>
  );
};

export default StudyspaceChatbotModal;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "./VideoCard.module.css";

const VideoCard = ({
  video,
  progress,
  studyspaceId,
  onRetryVideo,
  onDeleteVideo,
  onInfoClick,
  isEditing,
  selectallActive, // select all button signal
  onSelectVideo,
  selectedVideos,
}) => {
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(selectedVideos.includes(video._id));

  const handleCheckboxChange = () => {
    // setIsSelected(!isSelected);
    onSelectVideo(video._id);
  };

  useEffect(() => {
    setIsSelected(selectedVideos.includes(video._id));
  }, [selectedVideos, video._id]);

  // deselect all when exiting edit mode
  useEffect(() => { 
    if (!isEditing) setIsSelected(false);
  }, [isEditing]);

  // handle select all
  useEffect(() => { 
    setIsSelected(selectallActive);
  }, [selectallActive]);

  const renderProgressBar = (videoId, videoLink, source) => {
    const videoProgress = progress[videoId];
    if (!videoProgress) return null;

    if (videoProgress.status === "failed" && source === "transcript") {
      return (
        <div className={styles.retryContainer}>
          <button
            className={styles.retryButton}
            onClick={() => onDeleteVideo(videoId)}
          >
            Delete
          </button>
          <p className={styles.failedText}>Transcript Upload Failed</p>
        </div>
      );
    }

    if (videoProgress.status === "failed" && source === "youtube") {
      return (
        <div className={styles.retryContainer}>
          <button
            className={styles.retryButton}
            onClick={() => onRetryVideo(videoLink)}
          >
            Retry
          </button>
          <p className={styles.failedText}>Video Upload Failed</p>
        </div>
      );
    }

    if (videoProgress.status === "retrying") {
      return (
        <div className={styles.progressContainer}>
          <p className={styles.progressText}>Attempting Retry...</p>
        </div>
      );
    }

    if (videoProgress.status === "pending") {
      return (
        <div className={styles.progressContainer}>
          <p className={styles.progressText}>Pending...</p>
        </div>
      );
    }

    if (videoProgress.status === "completed") return null;

    return (
      <div className={styles.progressBarContainer}>
        <div
          className={styles.progressBar}
          style={{ width: `${videoProgress.progress}%` }}
        ></div>
        <p className={styles.progressText}>
          {videoProgress.status} ({videoProgress.progress}%)
        </p>
      </div>
    );
  };

  return (
    <div
      className={`
        ${progress[video._id]?.status === "failed" ? styles.failed : ""}
        ${styles.videoCard}
        ${progress[video._id]?.status !== "completed" ? `${styles.disabled}` : ""}
        ${
          progress[video._id]?.status !== "completed" &&
          progress[video._id]?.status !== "failed"
            ? styles.highlight
            : ""
        }
      `}
      onClick={() => {
        if (progress[video._id]?.status === "completed") {
          navigate(`/watch/${video._id}`, {
            state: { videoTitle: video.title, studyspaceId },
          });
        }
      }}
    >
      {/* Checkbox for edit mode */}
      {isEditing && (
        <label class={styles.checkboxContainer} onClick={(e) => e.stopPropagation()}>
          <input
            type="checkbox"
            className={styles.selectCheckbox}
            checked={isSelected}
            onChange={handleCheckboxChange}
            onClick={(e) => e.stopPropagation()} // Prevent card click when selecting
          />
          <span class={styles.checkmark}></span>
        </label>
      )}

      {video.source === "youtube" ? (
        <img
          src={video.thumbnailUrl}
          alt="Video Thumbnail"
          className={`${styles.videoThumbnail} ${
            progress[video._id]?.status !== "completed" ? styles.processing : ""
          }`}
        />
      ) : (
        <div
          className={`${styles.iconContainer} ${
            progress[video._id]?.status !== "completed" ? styles.processing : ""
          }`}
        >
          <FontAwesomeIcon icon={faFileAlt} className={styles.transcriptIcon} />
        </div>
      )}

      {/* Info Button */}
      <div className={styles.infoButtonContainer}>
        <button
          className={styles.infoButton}
          onClick={(e) => {
            e.stopPropagation();
            onInfoClick(video);
          }}
        >
          <FontAwesomeIcon icon={faCircleInfo} />
        </button>
      </div>

      <div className={styles.videoInfo}>
        <h3 className={styles.videoTitle}>{video.title}</h3>
        {video.source === "youtube" ? (
          <p className={styles.videoAuthor}>by {video.author}</p>
        ) : (
          <p className={styles.videoAuthor}>by You</p>
        )}

        <div className={styles.progressOverlay}>
          {renderProgressBar(video._id, video.link, video.source)}
        </div>
      </div>
    </div>
  );
};

export default VideoCard;

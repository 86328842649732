import React from "react";
import styles from "./VideoInfoPanel.module.css";

const formatToLocalTime = (isoDate) => {
  if (!isoDate) return { date: "Unknown Date", time: "" };

  try {
    const date = new Date(isoDate);
    return {
      date: date.toLocaleDateString(undefined, { 
        year: "numeric", 
        month: "short", 
        day: "numeric" 
      }),
      time: date.toLocaleTimeString(undefined, { 
        hour: "2-digit", 
        minute: "2-digit", 
        hour12: true 
      }),
    };
  } catch (error) {
    return { date: "Invalid Date", time: "" };
  }
};

const VideoInfoPanel = ({ isOpen, video, onClose }) => {
  const { date, time } = video ? formatToLocalTime(video.publishDate) : {};

  return (
    <div
      className={`${styles.videoInfoPanel} ${isOpen ? styles.open : ""}`}
      style={{
        opacity: isOpen ? 1 : 0, // Use opacity instead of removing from DOM
        pointerEvents: isOpen ? "auto" : "none", // Prevent interaction when hidden
      }}
    >
      {/* 🔳 Header with Close Button */}
      <div className={styles.infoHeader}>
        <h3 className={styles.videoTitle}>{video?.title}</h3>
        <button className={styles.closeButton} onClick={onClose}>✕</button>
      </div>

      {/* 📄 Video Details */}
      <div className={styles.infoContent}>
        <p><strong>Author:</strong> {video?.author}</p>

        {/* 📅 Publish Date & Time (Now on the Same Line) */}
        <p className={styles.publishLine}>
          <strong>Published on:</strong> 
          <span className={styles.publishDate}>{date}</span>
          <span className={styles.publishTime}>{time}</span>
        </p>

        {/* 📄 Description */}
        <div className={styles.descriptionContainer}>
          <p><strong>Description:</strong></p>
          <p className={styles.videoDescription}>{video?.description || "No description available."}</p>
        </div>
      </div>
    </div>
  );
};

export default VideoInfoPanel;

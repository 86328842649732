import { useEffect, useRef } from "react";

const MyComponent = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      console.log("rolling");
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div ref={containerRef} style={{ height: "100vh", overflowY: "auto" }}>
      <h1>Scroll inside this div</h1>
      <div style={{ height: "200vh" }} />
    </div>
  );
};

export default MyComponent;

import React, { useEffect, useState, useMemo } from "react";
// import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { timeInSeconds, getSegment, extractYouTubeID } from "../helper";
import WatchPageLayout from "../watchPageLayout";
import YouTubePlayer from "../component/YouTubePlayer";
import Outline from "../component/Outline";
import Chatbox from "../component/Chatbox";
import { Resizable } from "re-resizable";
import styles from "../Watch.module.css";
import { DesktopWatchTour } from "./DesktopWatchTour";
import { dotSpinner } from "ldrs";
import toastify from "../../../toastify";
import { Loading } from "../../components/Loading";
import { DesktopVideoInfoDetailed } from "./DesktopVideoInfoDetailed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";

dotSpinner.register();

export const DesktopWatchDetailed = ({ toggleVersion }) => {
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initcurrentTime = params.get("start")
    ? parseInt(params.get("start"), 10)
    : 0;
  const [currentTime, setCurrentTime] = useState(initcurrentTime);
  const [seekTo, setSeekTo] = useState(undefined);
  const [seekToTrigger, setSeekToTrigger] = useState(0);
  const [input, setInput] = useState("");
  const [videoId, setVideoId] = useState(null);
  const [segments, setSegments] = useState([]);
  const [currentSegment, setCurrentSegment] = useState(null);
  const [lightsOn, setLightsOn] = useState(true);
  // Current segment introduction name
  const [currentSegmentIntro, setCurrentSegmentIntro] = useState("");
  const [{ videoWidth, videoHeight }, setVideoSize] = useState({
    videoWidth: "65%",
    videoHeight: "100%",
  });

  const [timestamps, setTimestamps] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [source, setSource] = useState(null);

  const token = localStorage.getItem("token");

  // Fetch the segments of the video
  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const res = await getSegment(id, token);
        const data = res.data;

        if (data.segments) {
          setSegments(data.segments);

          const timestampsArray = data.segments.map((segment) => ({
            content: segment.title,
            timestamp: segment.start,
          }));

          setTimestamps(timestampsArray);
        }
        if (data.link) {
          setVideoId(extractYouTubeID(data.link));
        }

        if (data.source) {
          setSource(data.source);
        }

        if (data.segments && data.link) {
          setDataLoaded(true); // Set loading to false when all data is fetched
        }
      } catch (err) {
        toastify.error("Failed to load video, please refresh the page!");
        setDataLoaded(true);
      }
    };

    if (id) fetchSegments();
  }, [id]);

  // Find the current segment based on the current time
  useEffect(() => {
    const findCurrentSegment = () => {
      const current =
        segments
          .slice()
          .reverse()
          .find((segment) => timeInSeconds(segment.start) <= currentTime) ||
        segments[0];
      setCurrentSegment(current);
    };
    if (segments.length > 0) findCurrentSegment();
  }, [currentTime, segments]);

  // Handle time update from the YouTube player
  const handleTimeUpdate = (time) => {
    setCurrentTime(time);
  };

  // Handle seek from the outline
  const handleSeek = (time) => {
    setSeekTo(timeInSeconds(time));
    setSeekToTrigger(seekToTrigger + 1);
    setCurrentTime(timeInSeconds(time));
  };
  // Callback for when YouTube player is loaded
  const handlePlayerLoaded = () => {
    setPlayerLoaded(true); // Set playerLoaded to true when player is ready
  };

  // Memoize the YouTube player component only if source is "youtube"
  const memoizedYouTubePlayer = useMemo(() => {
    if (source === "youtube") {
      return (
        <YouTubePlayer
          videoId={videoId}
          onTimeUpdate={handleTimeUpdate}
          seekTo={seekTo}
          seekToTrigger={seekToTrigger}
          initialTime={initcurrentTime}
          onPlayerLoaded={handlePlayerLoaded} // Callback when player is loaded
        />
      );
    }
    return null;
  }, [videoId, seekTo, seekToTrigger, initcurrentTime, source]);

  return (
    <WatchPageLayout lightsOn={lightsOn} setLightsOn={setLightsOn}>
      {(!dataLoaded || (source === "youtube" && !playerLoaded)) && <Loading />}
      <div
        className={`w-full h-full ${
          dataLoaded && (source !== "youtube" || playerLoaded)
            ? "opacity-100"
            : "opacity-0"
        } transition-opacity duration-500 ease-in-out`}
      >
        <button
          onClick={() => {
            // Switch version and update localStorage
            toggleVersion();
          }}
          className="bg-blue-500 text-white p-3 rounded-full shadow-lg pointer-events-auto fixed bottom-4 left-4 z-10"
          aria-label={"Switch to Detailed Version"}
          title={"Switch to Detailed Version"}
        >
          <FontAwesomeIcon icon={faExchangeAlt} size="lg" />
        </button>
        {/* Item for the DesktopTour component */}
        {dataLoaded && playerLoaded && <DesktopWatchTour />}
        <div className={styles[`watch-container`]}>
          {/* Show the current segment introduction only when the movie mode is on */}
          {!lightsOn && (
            <div className="absolute top-[4vh] text-white text-3xl font-bold z-10">
              {currentSegmentIntro}
            </div>
          )}
          {/* Youtube Video Player + Current Segmet Info */}
          <Resizable
            size={{
              width: lightsOn ? videoWidth : "85%",
              height: videoHeight,
            }}
            minWidth="35%"
            maxWidth="85%"
            onResizeStop={(e, _, ref, d) => {
              setVideoSize({
                videoWidth: ref.style.width,
                videoHeight: ref.style.height,
              });
            }}
            className="flex flex-col gap-4 relative items-center"
          >
            {source === "youtube" && (
              <div
                id="youtube-video-player"
                className="relative w-[calc(100%-10px)] pt-[56.25%]"
              >
                {memoizedYouTubePlayer}
              </div>
            )}
            {/* Youtube Video Player */}

            {/* Current Segment Info */}
            {lightsOn && (
              <DesktopVideoInfoDetailed
                _id={id}
                currentSegment={currentSegment}
                currentSegmentIntro={currentSegmentIntro}
                onSeek={handleSeek}
              />
            )}
          </Resizable>

          <div
            className={`${lightsOn ? "flex" : "hidden"} flex-col gap-4 overflow-auto h-[100%] flex-1`}
          >
            <Outline
              _id={id}
              onSeek={handleSeek}
              currentSegment={currentSegment}
              setCurrentSegmentIntro={setCurrentSegmentIntro}
              timestamps={timestamps}
            />
            <Chatbox
              _id={id}
              input={input}
              setInput={setInput}
              onSeek={handleSeek}
              currentSegment={currentSegment}
              currentTime={currentTime}
            />
          </div>
        </div>
      </div>
    </WatchPageLayout>
  );
};

// DesktopWatchSimple.jsx

import React, { useEffect, useState, useMemo, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { timeInSeconds, getSegment, extractYouTubeID } from "../../helper";
import WatchPageLayout from "../../watchPageLayout";
import YouTubePlayer from "../../component/YouTubePlayer";
import { Loading } from "../../../components/Loading";
import toastify from "../../../../toastify";
import { DesktopVideoInfo } from "./DesktopVideoInfo";
import { TimelineSidebar } from "../TimelineSidebar";
import { TranscriptSidebar } from "./TranscriptSidebar"; // Updated import path
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const DesktopWatchSimple = ({ toggleVersion }) => {
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initcurrentTime = params.get("start")
    ? parseInt(params.get("start"), 10)
    : 0;
  const [currentTime, setCurrentTime] = useState(initcurrentTime);
  const [timeFromChatbot, setTimeFromChatbot] = useState();
  const [seekTo, setSeekTo] = useState(undefined);
  const [seekToTrigger, setSeekToTrigger] = useState(0);
  const [segments, setSegments] = useState([]);
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [currentSegment, setCurrentSegment] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [source, setSource] = useState(null);
  const [videoId, setVideoId] = useState(null);

  const [isVideoHidden, setIsVideoHidden] = useState(false);

  // Lifted state for content items and current content index
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [contentItems, setContentItems] = useState([]);

  const [isTimelineCollapsed, setIsTimelineCollapsed] = useState(false);
  const [isTranscriptCollapsed, setIsTranscriptCollapsed] = useState(false);

  // New state to control which tab is active on the right side: "transcript" or "chatbot"
  const [rightTab, setRightTab] = useState("chatbot");

  // New state for external messages to the chatbot
  const [externalMessage, setExternalMessage] = useState(null);

  const token = localStorage.getItem("token");
  
  // Fetch the segments of the video
  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const res = await getSegment(id, token);
        const data = res.data;

        if (data.segments) {
          setSegments(data.segments);
        }
        if (data.link) {
          setVideoId(extractYouTubeID(data.link));
        }
        if (data.source) {
          setSource(data.source);
        }
        if (data.segments && data.link) {
          setDataLoaded(true);
        }
      } catch (err) {
        toastify.error("Failed to load video, please refresh the page!");
        setDataLoaded(true);
      }
    };

    if (id) fetchSegments();
  }, [id, token]);

  // Set the current segment index to the time from the chatbot
  useEffect(() => {
    if (timeFromChatbot) {
      const orderedSegments = segments.sort(
        (a, b) => timeInSeconds(a.start) - timeInSeconds(b.start)
      );

      const indexFromChatBot =
        orderedSegments.findIndex(
          (segment) => timeInSeconds(segment.start) > timeFromChatbot
        ) - 1;

      if (indexFromChatBot === -1) {
        setCurrentSegmentIndex(orderedSegments.length - 1);
        return;
      }

      setCurrentSegmentIndex(indexFromChatBot);
    }
  }, [timeFromChatbot, segments]);

  // Find the current segment based on the current time
  useEffect(() => {
    if (segments.length > 0) {
      if (manualUpdate.current) return;

      const current =
        segments
          .slice()
          .reverse()
          .find((segment) => timeInSeconds(segment.start) <= currentTime) || segments[0];
      const index = segments.findIndex((segment) => segment === current);
  
      if (currentSegmentIndex !== index) {
        setCurrentSegmentIndex(index);
      }
    }
  }, [currentTime, segments, currentSegmentIndex]);

  // Update currentSegment when currentSegmentIndex changes
  useEffect(() => {
    if (segments.length > 0) {
      setCurrentSegment(segments[currentSegmentIndex]);
    }
  }, [currentSegmentIndex, segments]);

  const handleTimeUpdate = (time) => {
    setCurrentTime(time);
  if (manualUpdate.current) {
    manualUpdate.current = false;
  }
  };

  const manualUpdate = useRef(false);

  // Handle seek from the content
  const handleSeek = (time) => {
    const timeInSec = typeof time === "number" ? time : timeInSeconds(time);
    manualUpdate.current = true;
    setSeekTo(timeInSec);
    setSeekToTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    if (source === "transcript") {
      setIsTimelineCollapsed(false);
      setIsTranscriptCollapsed(false);
    }
  }, [source]);

  const handlePlayerLoaded = () => {
    setPlayerLoaded(true);
  };

  const getCurrentContentStartTime = () => {
    if (contentItems && contentItems[currentContentIndex]) {
      return contentItems[currentContentIndex].start;
    }
  };

  const initialPlayerTime = getCurrentContentStartTime();

  const memoizedYouTubePlayer = useMemo(() => {
    if (source === "youtube" && !isVideoHidden) {
      return (
        <YouTubePlayer
          videoId={videoId}
          onTimeUpdate={handleTimeUpdate}
          seekTo={seekTo}
          seekToTrigger={seekToTrigger}
          initialTime={initialPlayerTime}
          onPlayerLoaded={handlePlayerLoaded}
        />
      );
    }
    return null;
  }, [
    videoId,
    seekTo,
    seekToTrigger,
    source,
    isVideoHidden,
    initialPlayerTime,
  ]);

  const startOnboarding = () => {
    // Implement onboarding if needed
  };

  return (
    <WatchPageLayout lightsOn={true}>
      {(!dataLoaded || (source === "youtube" && !playerLoaded)) && <Loading />}
      <div
        className={`w-full h-full ${
          dataLoaded && (source !== "youtube" || playerLoaded)
            ? "opacity-100"
            : "opacity-0"
        } transition-opacity duration-500 ease-in-out`}
      >
        <div className="w-full h-full flex flex-row">
          {/* Timeline Sidebar */}
          <TimelineSidebar
            segments={segments}
            currentSegmentIndex={currentSegmentIndex}
            setCurrentSegmentIndex={setCurrentSegmentIndex}
            handleSeek={handleSeek}
            isTimelineCollapsed={isTimelineCollapsed}
            setIsTimelineCollapsed={setIsTimelineCollapsed}
          />

          {/* Main Content Area */}
          <div className="flex-1 flex flex-col items-center">
            {source === "youtube" && (
              <div className="w-full flex justify-center mt-3 mb-2">
                <button
                  onClick={() => setIsVideoHidden((prev) => !prev)}
                  className="flex items-center space-x-1 bg-gray-100 hover:bg-blue-500 text-blue-500 hover:text-white font-medium px-3 py-1 rounded-full shadow-sm transition duration-300 ease-in-out"
                >
                  <FontAwesomeIcon
                    icon={isVideoHidden ? faEye : faEyeSlash}
                    size="sm"
                  />
                  <span className="text-sm">{isVideoHidden ? "Show" : "Hide"}</span>
                </button>
              </div>
            )}

            {!isVideoHidden && source === "youtube" && (
              <div className="w-full max-w-[55vw]">
                <div className="relative w-full pt-[56.25%]">
                  {memoizedYouTubePlayer}
                </div>
              </div>
            )}

            <div
              className={`w-full ${
                isVideoHidden ? "h-full" : ""
              } max-w-[55vw] flex-grow`}
            >
              <DesktopVideoInfo
                currentSegment={currentSegment}
                segments={segments}
                segmentsLength={segments.length}
                currentSegmentIndex={currentSegmentIndex}
                setCurrentSegmentIndex={setCurrentSegmentIndex}
                startOnboarding={startOnboarding}
                handleSeek={handleSeek}
                toggleVersion={toggleVersion}
                currentTime={currentTime}
                contentItems={contentItems}
                setContentItems={setContentItems}
                currentContentIndex={currentContentIndex}
                setCurrentContentIndex={setCurrentContentIndex}
                isTimelineCollapsed={isTimelineCollapsed}
                setIsTimelineCollapsed={setIsTimelineCollapsed}
                isTranscriptCollapsed={isTranscriptCollapsed}
                setIsTranscriptCollapsed={setIsTranscriptCollapsed}
                source={source}
                rightTab={rightTab}              // Pass rightTab
                setRightTab={setRightTab}        // Pass setRightTab
                externalMessage={externalMessage}          // Pass externalMessage
                setExternalMessage={setExternalMessage}    // Pass setExternalMessage
              />
            </div>
          </div>

          {/* Modified TranscriptSidebar to have tabs for transcript/chatbot */}
          <TranscriptSidebar
            currentSegment={currentSegment}
            handleSeek={handleSeek}
            currentTime={currentTime}
            isTranscriptCollapsed={isTranscriptCollapsed}
            setIsTranscriptCollapsed={setIsTranscriptCollapsed}
            rightTab={rightTab}              // Pass rightTab state here
            setRightTab={setRightTab}        // Pass setter here
            _id={id}                         // Pass video id for chatbot
            externalMessage={externalMessage}          // Pass externalMessage
            setExternalMessage={setExternalMessage}    // Pass setExternalMessage
          />
          
        </div>
      </div>
    </WatchPageLayout>
  );
};

// Chatbox.jsx
import { FaTrash, FaExpand, FaCompress } from "react-icons/fa";
import React, { useState, useRef, useEffect } from "react";
import { getAnswerInVideo, secondsToTime } from "../helper";
import ReactMarkdown from "react-markdown";
import styles from "./Chatbox.module.css"; // Importing CSS module
import { HiOutlineLightBulb } from "react-icons/hi";
import { quantum } from "ldrs";
import { useNavigate } from "react-router-dom";
import toastify from "../../../toastify";
import { useFloating, offset, flip, shift, autoUpdate } from "@floating-ui/react";

quantum.register();

const Chatbox = ({
  _id,
  onSeek,
  currentSegment,
  close,
  setTimeFromChatbot,
  currentTime,
  externalMessage,    // Received from TranscriptSidebar.jsx
  setExternalMessage, // Received from TranscriptSidebar.jsx
}) => {
  const {
    refs: floatingRefs,
    floatingStyles,
    update,
  } = useFloating({
    placement: 'top',
    middleware: [
      offset(8), // Add a little space between menu and selection
      flip({ fallbackPlacements: ['bottom'] }), // Flip below if needed
      shift({ padding: 8 }) // Prevents clipping near edges
    ],
  });
  
  const virtualRef = useRef(null);

  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false); // State to track response generation
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [selectedText, setSelectedText] = useState(null); // State for selected text
  const [actionMenuVisible, setActionMenuVisible] = useState(false); // State for showing action menu
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 }); // Position for action menu
  const menuRef = useRef(null); // Reference to the action menu
  const [isPopupOpen, setIsPopupOpen] = useState(false); // ✅ State for popup mode
  const togglePopup = () => setIsPopupOpen(!isPopupOpen);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [config, setConfig] = useState(() => {
    // Getting the stored value from localStorage during initial state setup
    return localStorage.getItem("config") || "moderate";
  });
  const navigate = useNavigate();

  const isMobile = window.innerWidth < 980;

  const textAreaRef = useRef(null);
  const chatHistoryRef = useRef(null); // Ref for chat history div

  const isSignedIn = localStorage.getItem("token") !== "DEMO_TOKEN";

  const handleTextareaFocus = () => {
    setIsTextareaFocused(true);
  };

  const handleTextareaBlur = () => {
    setIsTextareaFocused(false);
  };

  useEffect(() => {
    return () => {
      virtualRef.current = null;
    };
  }, []);
  
  useEffect(() => {
    // ✅ Load messages from localStorage when component mounts
    const storedMessages = localStorage.getItem(`chat_history_${_id}`);
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
  }, [_id]);
  
  useEffect(() => {
    const handleSelection = () => {
      const selection = window.getSelection();
      const text = selection.toString().trim();

      if (text.length > 0) {
        const selectedNode = selection.anchorNode;
        const isChatbotMessage = selectedNode?.parentElement?.closest(
          '[data-chatbot-message="true"]'
        );

        if (isChatbotMessage) {
          const range = selection.getRangeAt(0);
          const rect = range.getBoundingClientRect();

          // Set up a virtual element that Floating UI can track
          virtualRef.current = {
            getBoundingClientRect: () => rect,
          };

          // ⛏ Floating UI needs this to run in the next tick to avoid null refs
          requestAnimationFrame(() => {
            floatingRefs.setReference(virtualRef.current);
            update(); // ensure it's placed correctly
          });

          setSelectedText(text);
          setActionMenuVisible(true);
        } else {
          setActionMenuVisible(false);
        }
      } else {
        setActionMenuVisible(false);
      }
    };

    document.addEventListener("mouseup", handleSelection);
    document.addEventListener("touchend", handleSelection);
    return () => {
      document.removeEventListener("mouseup", handleSelection);
      document.removeEventListener("touchend", handleSelection);
    };
  }, [floatingRefs, update]);

  

  const handleActionClick = (action) => {
    console.log("Action Clicked:", action);
    window.getSelection().removeAllRanges();
    // Logic to handle different actions based on the selected text
    switch (action) {
      case "example":
        sendMessage(`Give more examples of "${selectedText}".`);
        break;
      case "explanation":
        sendMessage(`Explain more about "${selectedText}".`);
        break;
      case "alternate":
        sendMessage(`Can you explain "${selectedText}" in another way?`);
        break;
      default:
        break;
    }
    setActionMenuVisible(false);
  };

  useEffect(() => {
    if (textAreaRef.current) {
      adjustTextAreaHeight(textAreaRef.current);
    }
  }, [input]);

  useEffect(() => {
    localStorage.setItem("config", config);
  }, [config]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    adjustTextAreaHeight(e.target);
  };

  const adjustTextAreaHeight = (textArea) => {
    textArea.style.height = "auto";
    textArea.style.height =
      textArea.scrollHeight < 100 ? `${textArea.scrollHeight + 2}px` : "100px";
  };

  const modifyLinkText = (text) => {
    const regex =
      /\[Video\]\((http:\/\/localhost:3000\/watch\/([^?]+)\?start=(\d+))\)/g;
    return text.replace(regex, (match, url, id, start) => {
      if (id === _id) {
        return ` [${secondsToTime(start)}](${url})`;
      }
      return match;
    });
  };

  const sendMessage = async (input) => {
    console.log("sendMessage called with:", input); // Debugging
    if (!input.trim() || isGenerating) return;
    setIsGenerating(true); // Indicate that a response is being generated
    const userMessage = { author: "user", text: input.trim() };
    setMessages((messages) => [...messages, userMessage]);
    setInput("");

    // Prepare chat history
    let history = [];
    if (messages.length > 0) {
      history = messages.map((msg) => ({
        role: msg.author.toLowerCase(),
        content: msg.text,
      }));
    }

    // Reset textarea height
    textAreaRef.current.style.height = "auto";

    // Add a temporary message indicating the system is generating an answer
    setMessages((messages) => [
      ...messages,
      { author: "assistant", text: "", isGenerating: true },
    ]);

    const token = localStorage.getItem("token");
    console.log("Current Time:", currentTime);
    const response = await getAnswerInVideo(
      history,
      input.trim(),
      _id,
      config,
      Math.floor(currentTime),
      token,
    );

    if (response && response.data.answer) {
      // Replace the temporary message with the actual answer
      const modifiedAnswer = modifyLinkText(response.data.answer);

      setMessages((prevMessages) => {
        const updatedMessages = [
          ...prevMessages.slice(0, -1), // Remove temporary "isGenerating" message
          {
            author: "assistant",
            text: modifiedAnswer,
            markdown: true,
            isNew: true,
          },
        ];
    
        // ✅ Store in localStorage
        localStorage.setItem(
          `chat_history_${_id}`,
          JSON.stringify(updatedMessages.slice(-50)) // Keep last 50 messages
        );
    
        return updatedMessages; // Update state
      });
    } else {
      // Handle errors or no response cases
      setMessages((messages) => [
        ...messages.slice(0, -1),
        {
          author: "assistant",
          text: "Unable to retrieve an answer, please try again later.",
          isNew: true,
        },
      ]);
    }
    setIsGenerating(false); // Reset the generating state
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage(input);
    }
  };

  const handleQuestionClick = (question) => {
    // console.log(question);
    setInput(question);
  };

  const handleQuestionClickAndSubmit = (question) => {
    sendMessage(question);
  };

  const codeBlockStyle = {
    backgroundColor: "#000",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    overflowX: "auto",
  };

  const handleLinkClick = (event, href) => {
    event.preventDefault();
    
    if (isMobile) {
      const startTime = parseInt(new URL(href).searchParams.get("start"), 10);
      setTimeFromChatbot(startTime);
      close();
      return;
    }

    const url = new URL(href, window.location.origin);
    const params = new URLSearchParams(url.search);
    if (href.includes(_id) && params.has("start")) {
      event.preventDefault();
      onSeek(params.get("start"));
      close();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActionMenuVisible(false); // Close menu if click is outside
        window.getSelection().removeAllRanges(); // Clear the text selection
      }
    };

    document.addEventListener("mousedown", handleClickOutside); // Listen for clicks outside
    document.addEventListener("touchstart", handleClickOutside); // Handle touch for mobile

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    const timeoutIds = [];
    messages.forEach((msg, index) => {
      if (msg.isNew) {
        const timeoutId = setTimeout(() => {
          setMessages((msgs) =>
            msgs.map((m, i) => (i === index ? { ...m, isNew: false } : m))
          );
        }, 500);
        timeoutIds.push(timeoutId);
      }
    });
    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [messages]);

  // Effect to handle external messages
  useEffect(() => {
    if (externalMessage) {
      sendMessage(externalMessage);
      setExternalMessage(null); // Reset externalMessage after sending
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalMessage]);

  const clearChatHistory = () => {
    setMessages([]);
    localStorage.removeItem(`chat_history_${_id}`);
    toastify.success("Chat history cleared!");
  };

  useEffect(() => {
    if (actionMenuVisible && floatingRefs.reference.current && floatingRefs.floating.current) {
      return autoUpdate(
        floatingRefs.reference.current,
        floatingRefs.floating.current,
        update
      );
    }
  }, [actionMenuVisible, floatingRefs, update]);
  
  return (
    
    <div className={isMobile ? "" : styles.chatArea} id="chatbox">
      {showConfirmDelete && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm">
            <h2 className="text-lg font-semibold text-gray-900">Clear Chat History?</h2>
            <p className="text-gray-600 text-sm mt-2">This action cannot be undone.</p>

            <div className="flex justify-end mt-4 space-x-3">
              <button 
                onClick={() => setShowConfirmDelete(false)} 
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400 transition"
              >
                Cancel
              </button>
              <button 
                onClick={() => { 
                  clearChatHistory();
                  setShowConfirmDelete(false)
                }} 
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={styles.chatComponentContainer}>
        {/* ✅ Chatbox Header with Buttons */}
        <div className="absolute top-3 right-4 flex space-x-3 z-50">
          {/* 🗑 Clear Chat History */}
          <button onClick={() => setShowConfirmDelete(true)} className="text-gray-500 hover:text-red-500 transition">
            <FaTrash size={18} />
          </button>

          {/* 🔲 Toggle Popup Mode */}
          {/* <button onClick={togglePopup} className="text-gray-500 hover:text-blue-500 transition">
            {isPopupOpen ? <FaCompress size={18} /> : <FaExpand size={18} />}
          </button> */}
        </div>

        <div className={styles.chatHistory} ref={chatHistoryRef}>
          {messages.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "130px",
                color: "#9e9e9e",
              }}
            >
              {currentSegment && (
                <>
                  <div className="relative inline-block mb-2">
                    <HiOutlineLightBulb size={30} />
                  </div>
                  <h4 className={styles.headingStyle}>
                      <>Ask me anything!</>
                  </h4>
                  <ul style={{ listStyleType: "none" }}>
                    {currentSegment.questions
                      .slice(0, 3)
                      .map((question, index) => (
                        <li
                          key={index}
                          className={styles.questionStyle}
                          onClick={() => {
                            console.log(isSignedIn);
                            if (isSignedIn) {
                              handleQuestionClickAndSubmit(question);
                            } else {
                              navigate("/signin");
                              toastify.info(
                                "Please sign in first."
                              );
                          }}}
                        >
                          {question}
                        </li>
                      ))}
                  </ul>

                  <div className="flex justify-center mt-6 mb-4">
                    <div
                      className="inline-flex border border-gray-300 rounded-md"
                      role="group"
                    >
                      <button
                        type="button"
                        className={`px-6 py-2 text-sm font-medium text-center rounded-l-lg ${config === "depth" ? "text-gray-900" : "text-gray-400"}`}
                        onClick={() => setConfig("depth")}
                      >
                        Depth
                      </button>
                      <button
                        type="button"
                        className={`px-6 py-2 text-sm font-medium text-center ${config === "moderate" ? "text-gray-900" : "text-gray-400"}`}
                        onClick={() => setConfig("moderate")}
                      >
                        Moderate
                      </button>
                      <button
                        type="button"
                        className={`px-6 py-2 text-sm font-medium text-center rounded-r-lg ${config === "broad" ? "text-gray-900" : "text-gray-400"}`}
                        onClick={() => setConfig("broad")}
                      >
                        Broad
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              {actionMenuVisible && (
                <div
                  ref={floatingRefs.setFloating}
                  style={{
                    ...floatingStyles,
                    zIndex: 9999,
                  }}
                  className="bg-white border border-gray-300 rounded-lg shadow-lg p-2 z-50 transition-all"
                  onMouseDown={(e) => e.stopPropagation()}
                  onTouchEnd={(e) => e.stopPropagation()}
                >
                  <button
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleActionClick("example");
                    }}
                    onTouchEnd={(e) => {
                      e.preventDefault();
                      handleActionClick("example");
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    More Examples
                  </button>
                  <button
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleActionClick("explanation");
                    }}
                    onTouchEnd={(e) => {
                      e.preventDefault();
                      handleActionClick("explanation");
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    More Explanation
                  </button>
                  <button
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleActionClick("alternate");
                    }}
                    onTouchEnd={(e) => {
                      e.preventDefault();
                      handleActionClick("alternate");
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Explain Another Way
                  </button>
                </div>
              )}

              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`${msg.author === "user" ? styles.messageUser : styles.messageBot}`}
                  data-chatbot-message={
                    msg.author === "assistant" ? "true" : "false"
                  }
                >
                  {msg.isGenerating ? (
                    <div className={styles.loadingIcon}>
                      <l-quantum
                        size="30"
                        speed="1.75"
                        color="black"
                      ></l-quantum>
                    </div>
                  ) : msg.markdown ? (
                    <div className={`${styles.newMessage}`}>
                      <ReactMarkdown
                        className="text-base leading-relaxed space-y-4"
                        components={{
                          a: ({ node, ...props }) => (
                            <a
                              {...props}
                              className="text-blue-500 underline cursor-pointer"
                              onClick={(event) =>
                                handleLinkClick(event, props.href)
                              }
                            >
                              {props.children}
                            </a>
                          ),
                          pre: ({ node, children, ...props }) => {
                            const hasCodeTag = React.Children.toArray(
                              children
                            ).some((child) => child.type === "code");
                            if (hasCodeTag) {
                              return (
                                <pre style={codeBlockStyle}>{children}</pre>
                              );
                            }
                            return <pre>{children}</pre>;
                          },
                          li: ({ node, ...props }) => (
                            <li className="mb-4" {...props} />
                          ),
                        }}
                      >
                        {msg.text}
                      </ReactMarkdown>
                    </div>
                  ) : (
                    <p>{msg.text}</p>
                  )}
                </div>
              ))}
              <div style={{ height: "18%" }}></div>{" "}
              {/* Spacer div for white space */}
            </>
          )}
        </div>

        <div
          className="flex flex-col justify-center items-center mb-3"
        >
          {isTextareaFocused &&
            !input &&
            messages.length !== 0 &&
            currentSegment &&
            !isGenerating && (
              <div className={styles.questionPrompt}>
                <ul className={styles.questionList}>
                  {currentSegment.questions
                    .slice(0, 3)
                    .map((question, index) => {
                      const reverseIndex =
                        currentSegment.questions.slice(0, 3).length - 1 - index;
                      return (
                        <li
                          key={index}
                          onMouseDown={() => {
                            console.log(isSignedIn);
                            if (isSignedIn) {
                              handleQuestionClick(question);
                            } else {
                              navigate("/signin");
                              toastify.info(
                                "Please sign in first."
                              );
                            }
                          }}
                          className={styles.questionStyle}
                          style={{
                            "--animation-delay": `${reverseIndex * 0.1}s`,
                          }}
                        >
                          {question}
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          {!isSignedIn && (
            <div className="ml-6 text-left self-start">
              
              <button
                onClick={() => navigate("/signin")}
                className="text-blue-500 underline hover:text-blue-700 text-xs ml-2"
              >
                Click here to sign in
              </button>
            </div>
          )}
          <div className={styles.inputContainer}>
            {/* Show demo usage left if not signed in */}

            <textarea
              ref={textAreaRef}
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              onFocus={handleTextareaFocus}
              onBlur={handleTextareaBlur}
              className={styles.textArea}
              placeholder={
                !isSignedIn
                  ? "Sign in to ask question"
                  : "Type your question here"
              }
              rows={1}
              disabled={!isSignedIn}
            />
            <button
              onClick={() => sendMessage(input)}
              className={styles.sendButton}
              disabled={
                !input.trim() ||
                isGenerating ||
                (!isSignedIn)
              }
            >
              {"Send"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbox;

import React, { useState, useRef } from "react";
import toastify from "../../toastify";
import emailjs from "emailjs-com";
import styles from "./header.module.css";

// Inline modal component
export const ContactModal = ({ isOpen, onClose }) => {
  const modalRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (!formData.name || !formData.email || !formData.message) {
      toastify.error("All fields are required.");
      return; // Stop the function if validation fails
    }

    setLoading(true);

    const templateParams = {
      name: formData.name, // Name from the state
      email: formData.email, // Email from the state
      message: formData.message, // Message from the state
    };

    // Sending the form data to EmailJS
    emailjs
      .send(
        "service_m9si88v", // Replace with your EmailJS service ID
        "template_gbra77m", // Replace with your EmailJS template ID
        templateParams, // The form data is passed directly
        "0N0G6lW4rh3cl0IMb", // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          // console.log(result.text);
          toastify.success(
            "Message Received! We will get back to you shortly.",
          );
          // Reset form data after successful submission
          setFormData({ name: "", email: "", message: "" });
        },
        (error) => {
          // console.log(error.text);
          toastify.error("Failed to send message, please try again.");
        },
      )
      .finally(() => {
        // Set loading to false after submission is done (success or failure)
        setLoading(false);
      });
  };

  // // Close modal if click happens outside of it
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       onClose();
  //     }
  //   };

  //   if (isOpen) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalInline} ref={modalRef}>
      <button className={styles.modalCloseButton} onClick={onClose}>
        &times;
      </button>
      <h2>Contact Us</h2>

      <div className={styles.formGroup}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name" // This matches the variable in your EmailJS template
          placeholder="Your name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email" // This matches the {{email}} variable in your EmailJS template
          placeholder="Your email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message" // This matches the {{message}} variable in your EmailJS template
          placeholder="Your message"
          value={formData.message}
          onChange={(e) =>
            setFormData({ ...formData, message: e.target.value })
          }
          required
        ></textarea>
      </div>
      <button
        className={styles.submitButton}
        onClick={handleSubmit}
        disabled={loading} // Disable the button while loading
      >
        {loading ? "Sending..." : "Send Message"}
      </button>
    </div>
  );
};

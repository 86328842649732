import React, { useState } from "react";
import styles from "./AddToModal.module.css";
import { getToken } from "../../../utils/helper";
import { deleteVideo } from "../helper";

const DeleteModal = ({ onClose, onExitEdit, studyspaceId, selectedVideos, setIsLoading: triggerLoading, refreshVideos }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null); // null | true | false

  const handleConfirm = async () => {
    setIsSubmitting(true);
    const token = getToken();

    try {
      const res = await deleteVideo(selectedVideos, studyspaceId, token);

      if (res.status === 200) {
				setSubmitSuccess(true);
				triggerLoading(true);
				onExitEdit();
				await refreshVideos();
      } else {
        console.error("Delete failed:", res);
        setSubmitSuccess(false);
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setSubmitSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h2>Delete Videos</h2>

        {submitSuccess === null ? (
          <>
            <p>Are you sure you want to delete the selected videos?</p>

            <div className={styles.buttonRow}>
              <button
                onClick={onClose}
                className={styles.closeButton}
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                className={styles.confirmButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Deleting..." : "Confirm"}
              </button>
            </div>
          </>
        ) : (
          <>
            <p>
              {submitSuccess
                ? "✅ Videos were successfully deleted."
                : "❌ Failed to delete videos. Please try again later."}
            </p>

            <div className={styles.buttonRow}>
								<button
									onClick={() => {
                      onClose();
									}}
									className={styles.confirmButton}
								>
                Done
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeleteModal;

// DesktopVideoInfo.jsx

import React, { useEffect, useRef, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faKeyboard, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { DesktopContent } from "./DesktopContent";
import toastify from "../../../../toastify";
import { timeInSeconds } from "../../helper";
import { CreditsContext } from "../../../header/CreditsContext";
import { feedbackFetch } from "../../../login-page/helper";

export const DesktopVideoInfo = (props) => {
  const {
    currentSegment,
    segments,
    segmentsLength,
    currentSegmentIndex,
    setCurrentSegmentIndex,
    startOnboarding,
    handleSeek,
    toggleVersion,
    currentTime,
    contentItems,
    setContentItems,
    currentContentIndex,
    setCurrentContentIndex,
    isTimelineCollapsed,
    setIsTimelineCollapsed,
    isTranscriptCollapsed,
    setIsTranscriptCollapsed,
    source,
    rightTab,           // Received from DesktopWatchSimple.jsx
    setRightTab,        // Received from DesktopWatchSimple.jsx
    externalMessage,    // Received from DesktopWatchSimple.jsx
    setExternalMessage, // Received from DesktopWatchSimple.jsx
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackSubmitting, setFeedbackSubmitting] = useState(false);
  const { fetchCredits } = useContext(CreditsContext);
  const token = localStorage.getItem("token");
  const isManualNavigation = useRef(false);
  // console.log(currentContentIndex)
  // **Added State Variables for Hint Functionality**
  const [showHint, setShowHint] = useState(false);
  const [hintDisplayed, setHintDisplayed] = useState(false);

//   console.log("Current Index:", currentContentIndex);
// console.log("Total Items:", contentItems);
// console.log("Next Item:", contentItems[currentContentIndex + 1]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowUp") {
        if (isTimelineCollapsed) {
          setIsTimelineCollapsed(false); // Expand timeline sidebar on first vertical navigation
        }
        handlePrevSection(); // Navigate to the previous section
      } else if (event.key === "ArrowDown") {
        if (isTimelineCollapsed) {
          setIsTimelineCollapsed(false); // Expand timeline sidebar on first vertical navigation
        }
        handleNextSection(); // Navigate to the next section
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentContentIndex, contentItems, segmentsLength, currentSegmentIndex]);

  useEffect(() => {
    if (!currentSegment) {
      setContentItems([]);
      return;
    }

    let items = [
      {
        type: "Summary",
        data: currentSegment.summary,
        start: timeInSeconds(currentSegment.start),
      },
      ...(currentSegment.goals || []).map((goal, index) => ({
        type: "Goal",
        data: { ...goal, step_number: index + 1 },
        start: goal.start
      })),
    ];

    for (let i = 0; i < items.length; i++) {
      if (items[i].start === undefined) {
        items[i].start = items[i - 1]
          ? items[i - 1].start + 10
          : timeInSeconds(currentSegment.start) + i * 10;
      }
    }

    for (let i = 0; i < items.length; i++) {
      items[i].end = items[i + 1] ? items[i + 1].start : Infinity;
    }

    setContentItems(items);
  }, [currentSegment]);

  // Update currentContentIndex based on currentTime, unless manual navigation is in progress
  useEffect(() => {
    if (isManualNavigation.current) {
      // Skip updating currentContentIndex during manual navigation
      return;
    }

    if (contentItems.length > 0) {
      const currentItemIndex = contentItems.findIndex(
        (item) =>
          currentTime >= item.start && currentTime < (item.end || Infinity)
      );

      if (
        currentItemIndex !== -1 &&
        currentContentIndex !== currentItemIndex
      ) {
        setCurrentContentIndex(currentItemIndex);
      }
    }
  }, [currentTime, contentItems]);

  useEffect(() => {
    if (isManualNavigation.current) {
      isManualNavigation.current = false;
    }
  }, [currentContentIndex]);

  const totalGoals = contentItems.filter((item) => item.type === "Goal").length;

  // Show the hint for 3 seconds on first interaction
  const triggerHint = () => {
    if (!hintDisplayed) {
      setShowHint(true);
      setHintDisplayed(true); // Mark hint as displayed
      setTimeout(() => setShowHint(false), 2500); // Hide hint after 3 seconds
    }
  };

  const handleNext = () => {
    console.log(contentItems)
    triggerHint(); 
    if (currentContentIndex < contentItems.length - 1) {
      const nextIndex = currentContentIndex + 1;
      isManualNavigation.current = true;
      setCurrentContentIndex(nextIndex);
      const nextItem = contentItems[nextIndex];
      if (nextItem && nextItem.start !== undefined) {
        handleSeek(nextItem.start);
      }
    } else if (currentSegmentIndex < segmentsLength - 1) {
      const newSegmentIndex = currentSegmentIndex + 1;
      setCurrentSegmentIndex(newSegmentIndex);
      isManualNavigation.current = true;
      setCurrentContentIndex(0);
      const nextSegmentStart = segments[newSegmentIndex].start;
      handleSeek(timeInSeconds(nextSegmentStart));
    } else {
      toastify.custom("You've completed the video. Great job!");
    }
  };

  const handlePrev = () => {
    triggerHint();
    if (currentContentIndex > 0) {
      const prevIndex = currentContentIndex - 1;
      isManualNavigation.current = true;
      setCurrentContentIndex(prevIndex);
      const prevItem = contentItems[prevIndex];
      if (prevItem && prevItem.start !== undefined) {
        handleSeek(prevItem.start);
      }
    } else if (currentSegmentIndex > 0) {
      const newSegmentIndex = currentSegmentIndex - 1;
      setCurrentSegmentIndex(newSegmentIndex);
      isManualNavigation.current = true;
      setCurrentContentIndex(-1);
      const prevSegmentStart = segments[newSegmentIndex].start;
      handleSeek(timeInSeconds(prevSegmentStart));
    } else {
      toastify.info("You are at the beginning of the video.");
    }
  };

  const handlePrevSection = () => {
    if (currentSegmentIndex > 0) {
      const prevSegmentIndex = currentSegmentIndex - 1;
      const prevSegmentStart = segments[prevSegmentIndex].start;
      handleSeek(timeInSeconds(prevSegmentStart));
      setCurrentSegmentIndex(prevSegmentIndex);
      setCurrentContentIndex(0);
    } else {
      toastify.info("You are at the first section.");
    }
  };

  // console.log(currentSegmentIndex)
  // Navigate to the next section
  const handleNextSection = () => {
    if (currentSegmentIndex < segmentsLength - 1) {
      const nextSegmentIndex = currentSegmentIndex + 1;
      setCurrentSegmentIndex(nextSegmentIndex);
      setCurrentContentIndex(0); // Start at the beginning of the new section
      const nextSegmentStart = segments[nextSegmentIndex].start;
      handleSeek(timeInSeconds(nextSegmentStart));
    } else {
      toastify.info("You have reached the last section.");
    }
  };

  useEffect(() => {
    if (currentContentIndex === -1 && contentItems.length > 0) {
      setCurrentContentIndex(contentItems.length - 1);
    }
  }, [contentItems, currentContentIndex]);

  if (!currentSegment) {
    return <EmptyInfo />;
  }

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setFeedback("");
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleFeedbackSubmit = async () => {
    setFeedbackSubmitting(true);
    const wordCount = feedback.trim().split(/\s+/).length;

    if (wordCount <= 1) {
      toastify.error("Please share a bit more detail.");
    } else {
      try {
        const res = await feedbackFetch(token, feedback);

        if (res.status === 200) {
          toastify.custom("❤️ Thank you for submitting feedback!");
          setFeedback("");
          fetchCredits();
        } else {
          toastify.error(res.data.detail);
        }
      } catch (error) {
        console.error("Error submitting feedback:", error);
        toastify.error(
          "An error occurred while submitting feedback! Please try again later."
        );
      }
    }

    setFeedbackSubmitting(false);
  };

  return (
    <div
      id="video-info-container"
      className="flex flex-col items-center justify-center w-full h-full bg-white overflow-hidden"
    >
      <div className="flex flex-col items-center justify-center w-full h-full relative">
        <div className="relative z-50"> 
          {/* **Hint UI** */}
          <div
            className={`absolute top-full mt-3 left-1/2 transform -translate-x-1/2 bg-white border border-gray-300 shadow-md text-gray-700 text-sm rounded-md px-4 py-3 transition-opacity duration-300 ${
              showHint ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
            style={{ width: "14rem" }}
          >
            {/* Keyboard Icon Section */}
            <div className="flex items-center justify-center mb-3">
              <FontAwesomeIcon icon={faKeyboard} className="text-blue-500 text-lg" />
              <span className="ml-2 text-sm font-medium">Shortcuts</span>
            </div>

            <hr className="border-gray-300 my-2" />

            {/* Navigate Cards Section */}
            <div className="flex items-center mb-2 justify-center">
              <FontAwesomeIcon icon={faArrowLeft} className="text-blue-500" />
              <FontAwesomeIcon icon={faArrowRight} className="text-blue-500 mx-2" />
              <span>Navigate Cards</span>
            </div>

            {/* Navigate Sections Section */}
            <div className="flex items-center">
              <FontAwesomeIcon icon={faArrowUp} className="text-blue-500 ml-7" />
              <FontAwesomeIcon icon={faArrowDown} className="text-blue-500 mx-2" />
              <span>Navigate Sections</span>
            </div>
          </div>

        </div>
        
        <div
          className={`${
            source === "youtube"
              ? "flex justify-center absolute w-full top-4 space-x-6 z-50"
              : "flex justify-center fixed bottom-[8rem] w-full space-x-6 z-50"
          }`}
        >
          <button
            onClick={handlePrev}
            className="bg-blue-500 hover:bg-blue-600 text-white py-3 px-4 rounded-full shadow-md focus:outline-none transition relative overflow-hidden"
            aria-label="Previous"
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>

          {/* Removed chatbot button here since we now have tabs on TranscriptSidebar */}
          
          <button
            onClick={handleNext}
            className="bg-blue-500 hover:bg-blue-600 text-white py-3 px-4 rounded-full shadow-md focus:outline-none transition relative overflow-hidden"
            aria-label="Next"
          >
            <FontAwesomeIcon icon={faArrowRight} size="lg" />
          </button>
        </div>

        {/* Feedback Button */}
        <div className="fixed bottom-1 right-3 z-50">
          <button
            className="bg-blue-500 text-white text-xs py-2 px-3 rounded-full shadow-md hover:bg-blue-600 focus:outline-none transition-all"
            onClick={handlePopupOpen}
          >
            <FontAwesomeIcon icon={faCommentDots} className="w-3 h-3 mr-1" />
            Give us Feedback
          </button>
        </div>

        {/* Feedback Modal */}
        {showPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div
              className="bg-white p-6 rounded-lg shadow-lg w-96"
              onClick={(e) => e.stopPropagation()}
            >
              <h2 className="text-lg font-semibold mb-4 text-gray-700">
                Share Your Feedback
              </h2>
              <textarea
                value={feedback}
                onChange={handleFeedbackChange}
                className="w-full h-32 border border-gray-300 rounded-lg p-3 text-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="Let us know what you think..."
              />
              <div className="flex justify-end space-x-3 mt-4">
                <button
                  onClick={handlePopupClose}
                  className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleFeedbackSubmit}
                  className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ${
                    feedbackSubmitting ? "bg-gray-400 cursor-not-allowed" : ""
                  }`}
                >
                  {feedbackSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Content */}
        <DesktopContent
          contentItems={contentItems}
          currentContentIndex={currentContentIndex}
          total_steps={totalGoals}
          setIsTranscriptCollapsed={setIsTranscriptCollapsed}
          handleCardNext={handleNext}
          rightTab={rightTab}              // Pass rightTab to DesktopContent
          setRightTab={setRightTab}        // Pass setRightTab to DesktopContent
          externalMessage={externalMessage}          // Pass externalMessage
          setExternalMessage={setExternalMessage}    // Pass setExternalMessage
        />
      </div>
    </div>
  );
};

export const EmptyInfo = () => {
  return (
    <div
      id="video-info-container"
      className="w-full h-full bg-white flex items-center justify-center"
    >
      <p>No content available.</p>
    </div>
  );
};

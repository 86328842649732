import React from "react";

export const GoalCardDesktop = ({
  total_steps,
  step_number,
  learning_goal,
  explanation,
}) => {
  return (
    <div
      className="bg-gradient-to-b from-white to-gray-50 px-6 py-4 rounded-xl shadow-md
    flex flex-col items-start border-l-4 border-indigo-500 relative max-w-[95%] overflow-auto"
    >
      {/* Top section: Learning Objective and Step Count */}
      <div className="flex justify-between items-center w-full mb-1">
        <p className="text-indigo-700 font-bold text-base tracking-wide">
          Learning Objective
        </p>
        <p className="text-gray-500 text-sm font-medium">
          {step_number} of {total_steps}
        </p>
      </div>

      {/* Learning Goal */}
      <p className="text-gray-900 text-lg font-semibold leading-snug mb-2">
        {learning_goal}
      </p>

      {/* Explanation */}
      <p className="text-gray-700 text-sm leading-relaxed">
        {explanation}
      </p>

      {/* Subtle background element */}
      <div className="absolute inset-0 rounded-xl bg-indigo-100 opacity-10 blur-md"></div>
    </div>
  );
};

// TranscriptSidebar.jsx

import React, { useEffect, useRef, useState } from "react";
import { secondsToTime } from "../../helper";
import { MdTextSnippet } from "react-icons/md";
import { FaRobot } from "react-icons/fa";
import Chatbox from "../../component/Chatbox";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export const TranscriptSidebar = ({
  currentSegment,
  handleSeek,
  currentTime,
  isTranscriptCollapsed,
  setIsTranscriptCollapsed,
  rightTab,
  setRightTab,
  _id,
  externalMessage, // Received from DesktopWatchSimple.jsx
  setExternalMessage, // Received from DesktopWatchSimple.jsx
}) => {
  const transcripts = currentSegment?.condensed_transcript || [];

  const transcriptRefs = useRef([]); // Ref array for transcript items
  const [sidebarWidth, setSidebarWidth] = useState("28vw"); // Default width

  useEffect(() => {
    const adjustWidth = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
  
      // Dynamically adjust width based on height & width
      let newWidth;
      if (screenHeight < 800) {
        newWidth = "40vw"; // Wider if screen height is small
      } else if (screenWidth < 1200) {
        newWidth = "30vw"; // Medium screens
      } else {
        newWidth = "28vw"; // Default width
      }
      console.log(screenHeight)
      console.log(newWidth)
      setSidebarWidth(newWidth);
    };
  
    adjustWidth(); // Initial call
    window.addEventListener("resize", adjustWidth);
    return () => window.removeEventListener("resize", adjustWidth);
  }, []);
  useEffect(() => {
    if (transcripts.length > 0 && transcriptRefs.current) {
      const activeIndex = transcripts.findIndex(
        (item, index) =>
          currentTime >= item.start &&
          currentTime < (transcripts[index + 1]?.start || Infinity)
      );
  
      if (activeIndex !== -1 && transcriptRefs.current[activeIndex]) {
        transcriptRefs.current[activeIndex].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [currentTime, transcripts]);
  
  

  if (!currentSegment) {
    return (
      <div
        className={`transition-all duration-300 ease-in-out ${
          isTranscriptCollapsed ? "w-14" : "w-[24rem]"
        } bg-white border-l border-gray-200 h-full flex flex-col`}
      >


        {isTranscriptCollapsed ? (
          // Collapsed: Show the currently active tab icon and name vertically
          <button
            onClick={() => setIsTranscriptCollapsed(false)}
            className="focus:outline-none flex flex-col items-center justify-center border-b border-gray-200 h-14"
            aria-label="Expand"
          >
            {rightTab === "transcript" ? (
              <>
                <MdTextSnippet className="mx-auto text-xl mb-1 text-blue-500" />
                <span className="text-[11px] text-blue-500 font-semibold leading-none">
                  Transcript
                </span>
              </>
            ) : (
              <>
                <FaRobot className="mx-auto text-xl mb-1 text-blue-500" />
                <span className="text-[11px] text-blue-500 font-semibold leading-none">
                  Chatbot
                </span>
              </>
            )}
          </button>
        ) : (
          <>
            {/* Tab Bar when expanded */}
            <div className="flex flex-row items-center justify-center border-b border-gray-200 w-full p-1">
              
              <button
                onClick={() => {
                  setRightTab("transcript");
                }}
                className={`flex-1 text-center text-sm py-2 ${
                  rightTab === "transcript"
                    ? "font-bold text-blue-600"
                    : "text-gray-600"
                }`}
              >
                <MdTextSnippet className="mx-auto text-xl mb-1" />
                Transcript
              </button>
              <button
                onClick={() => {
                  setRightTab("chatbot");
                }}
                className={`flex-1 text-center text-sm py-2 ${
                  rightTab === "chatbot"
                    ? "font-bold text-blue-600"
                    : "text-gray-600"
                }`}
              >
                <FaRobot className="mx-auto text-xl mb-1" />
                Chatbot
              </button>
            </div>
            <button
              onClick={() => setIsTranscriptCollapsed(true)}
              className="focus:outline-none flex items-center justify-center border-b border-gray-200 h-10"
              aria-label="Collapse"
            >
              <span className="text-[11px] text-blue-500 font-semibold leading-none py-2">
                Collapse
              </span>
            </button>
          </>
        )}
      </div>
    );
  }

  return (
    <div
  className={`relative transition-all duration-300 ease-in-out bg-white border-l border-gray-200 h-full flex flex-col`}
  style={{
    width: isTranscriptCollapsed ? "3.5rem" : sidebarWidth, // Dynamic width
    minWidth: isTranscriptCollapsed ? "auto" : "25rem", // Preserve min width
  }}
>
      {/* Mid Toggle Button (left edge of transcript sidebar) */}
      <button
          onClick={() => setIsTranscriptCollapsed(!isTranscriptCollapsed)}
          className="absolute left-[-0.75rem] top-1/2 translate-y-[-50%] bg-white border border-gray-300 shadow rounded-full p-1 z-50"
          aria-label={isTranscriptCollapsed ? "Expand Transcript" : "Collapse Transcript"}
        >
          {isTranscriptCollapsed ? (
            <FaChevronLeft className="text-lg text-blue-500" />
          ) : (
            <FaChevronRight className="text-lg text-gray-600" />
          )}
        </button>
      {isTranscriptCollapsed ? (
        // Collapsed: Show the currently active tab icon and name vertically
        <button
          onClick={() => setIsTranscriptCollapsed(false)}
          className="focus:outline-none flex flex-col items-center justify-center border-b border-gray-200 h-14"
          aria-label="Expand"
        >
          {rightTab === "transcript" ? (
            <>
              <MdTextSnippet className="mx-auto text-xl mb-1 text-blue-500" />
              <span className="text-[11px] text-blue-500 font-semibold leading-none">
                Transcript
              </span>
            </>
          ) : (
            <>
              <FaRobot className="mx-auto text-xl mb-1 text-blue-500" />
              <span className="text-[11px] text-blue-500 font-semibold leading-none">
                Chatbot
              </span>
            </>
          )}
        </button>
      ) : (
        <>
          {/* Tab Bar when expanded */}
          <div className="flex flex-row items-center justify-center border-b border-gray-200 w-full p-1">
            <button
              onClick={() => {
                setRightTab("transcript");
              }}
              className={`flex-1 text-center text-sm py-2 ${
                rightTab === "transcript"
                  ? "font-bold text-blue-600"
                  : "text-gray-600"
              }`}
            >
              <MdTextSnippet className="mx-auto text-xl mb-1" />
              Transcript
            </button>
            <button
              onClick={() => {
                setRightTab("chatbot");
              }}
              className={`flex-1 text-center text-sm py-2 ${
                rightTab === "chatbot"
                  ? "font-bold text-blue-600"
                  : "text-gray-600"
              }`}
            >
              <FaRobot className="mx-auto text-xl mb-1" />
              Chatbot
            </button>
          </div>
          {/* Content based on active tab */}
          <div className="overflow-y-auto p-2 w-full flex-1">
            {rightTab === "transcript" ? (
              transcripts && transcripts.length > 0 ? (
                <ul>
                  {transcripts.map((item, index) => {
                    const isActive =
                      currentTime >= item.start &&
                      currentTime < (transcripts[index + 1]?.start || Infinity);
                    return (
                      <li
                        key={index}
                        className={`mb-2 cursor-pointer p-1 rounded ${
                          isActive ? "bg-gray-200" : "hover:bg-gray-100"
                        }`}
                        ref={(el) => (transcriptRefs.current[index] = el)}
                        onClick={() => handleSeek(item.start)}
                      >
                        <div className="text-sm text-gray-700">
                          {item.content}
                        </div>
                        <div className="text-xs text-gray-500">
                          {secondsToTime(item.start)}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="text-sm text-gray-500">
                  No transcripts available.
                </p>
              )
            ) : (
              // Chatbot tab content
              <div className="h-full flex flex-col">
                <Chatbox
                  _id={_id}
                  currentSegment={currentSegment}
                  close={() => {
                    setRightTab("transcript"); // Optionally switch back to Transcript on close
                  }}
                  currentTime={currentTime}
                  onSeek={handleSeek}
                  setTimeFromChatbot={() => {}}
                  externalMessage={externalMessage} // Pass externalMessage to Chatbox
                  setExternalMessage={setExternalMessage} // Pass setExternalMessage to Chatbox
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VideoModalContext } from "../../VideoModalContext";
import { CreditsContext } from "../../header/CreditsContext";
import toastify from "../../../toastify";
import styles from "./ChatButtonGroup.module.css";
import { ChatbotIcon, ListIcon, EditIcon, IconsIcon, ImportIcon, DeleteIcon } from "./icon";

const TopButtonGroup = ({ videos, token, demoChatbotUsageLeft, setIsChatbotOpen, setIsEditing, isEditing, onSelectAll, selectedVideos, onAddToClick, setShowDeleteModal, onExitEdit }) => {
  const { toggleGenerateVideo } = useContext(VideoModalContext);
  const { fetchCredits } = useContext(CreditsContext);
  const navigate = useNavigate();

  const [isGridView, setIsGridView] = useState(true);

  const toggleView = () => {
    setIsGridView((prev) => !prev);
  };

  const handleEditClick = () => {
    // setEditing(!isEditing); // Toggle edit mode
    setIsEditing(!isEditing); // Pass the state to the parent
  };

  const handleCancelClick = () => {
    onExitEdit();
    // setEditing(false); // Exit edit mode
    setIsEditing(false); // Exit edit mode
  };

  const handleChatbotClick = () => {
    if (videos.length === 0) {
      toggleGenerateVideo();
    } else if (token !== "DEMO_TOKEN") {
      setIsChatbotOpen(true);
    } else if (demoChatbotUsageLeft <= 0) {
      toastify.info("You have used all your demo usages! Please sign in to continue.");
      navigate("/signin");
    } else {
      setIsChatbotOpen(true);
    }
  };

  return (
    <div className={styles.chatButtonContainer}>
      {isEditing ? (
        // Editing mode: Show "Cancel" and "Select All"
        <>
          <button
            className={`${styles.button} ${styles.cancelButton}`}
            id="studySpaceCancelButton"
            onClick={handleCancelClick}
          >
            Cancel
          </button>

          <button
            className={`${styles.button} ${styles.studySpaceEditButton}`}
            id="studySpaceEditButton"
            disabled={selectedVideos.length === 0}
            onClick={onAddToClick}
          >
            <ImportIcon />
            <span className={styles.lable}>Add to</span>
          </button>
          
          <button
            className={`${styles.button} ${styles.studySpaceDeleteButton}`}
            id="studySpaceEditButton"
            disabled={selectedVideos.length === 0}
            onClick={() => setShowDeleteModal(true)}
          >
            <DeleteIcon />
            <span className={styles.lable}>Delete</span>
          </button>

          <button
            className={`${styles.button} ${styles.selectAllButton}`}
            id="studySpaceSelectAllButton"
            onClick={onSelectAll} // Function to select all videos
          >
            Select All
          </button>
        </>
      ) : (
        // Normal mode: Show original buttons
        <>
          <button
            className={`${styles.button} ${styles.chatbotButton}`}
            id="studySpaceChatBot"
            onClick={handleChatbotClick}
          >
            <ChatbotIcon />
            <span className={styles.lable}>Chatbot</span>
          </button>

          <button
            className={`${styles.button} ${styles.studySpaceEditButton}`}
            id="studySpaceEditButton"
            onClick={handleEditClick}
          >
            <EditIcon />
            <span className={styles.lable}>Edit</span>
          </button>

          <button
            className={`${styles.button} ${styles.studySpaceViewButton}`}
            id="studySpaceViewButton"
            onClick={toggleView}
          >
            {isGridView ? <IconsIcon /> : <ListIcon />}
          </button>
        </>
      )}
    </div>
  );
};

export default TopButtonGroup;
